import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import Cookies from "js-cookie";
import { useHMSActions } from "@100mslive/react-sdk";
// import { selectPeers, useHMSActions, useHMSStore } from "@100mslive/react-sdk";
import { Color, DivCenter } from "../../../GlobalStyles";
import { SlotsContext } from "../../AppContext";
import Feedback from "../Mentors/Feedback/TempFeedback";
import StudentNotes from "../Students/StudentNotes/StudentNotes";
import UserInformationComponent from "./UserInformationComponent";
// import TimeService from "../../services/epochTime";
import "./DocumentsAndFeedback.css"; // Import CSS for animations
const DocumentsAndFeedback = ({
  studentJoined,
  handleEndSessionYes,
  // setOverflowHidden,
  // overflowHidden,
}) => {
  const hmsActions = useHMSActions();
  const checkUser = Cookies.get("user_type");
  const { slotsContextData, setSlotsContextData } = useContext(SlotsContext);
  console.log("slotsContextData", slotsContextData);
  const [showPdfUrl, setShowPdfUrl] = useState();
  const [feedbackFocused, setFeedbackFocused] = useState(false);
  const { isRoomJoinCalled } = hmsActions;
  console.log("isRoomJoinCalled", isRoomJoinCalled);

  return (
    <Box
      sx={{
        ...DivCenter,
        // height:
        //   studentJoined || slotsContextData.student_join_time !== null
        //     ? "100%"
        //     : "80%",
        width: ["/FeedbackOverview", "/FeedbackOverview/offline"].includes(
          window.location.pathname
        )
          ? "100%"
          : "70%", // Use only the left half of the page
        top: "0px",
        display: "flex",
        flexDirection: "column",
        // marginLeft: "10px",
        position: "relative", // Ensure that the position is relative to place the overlay correctly
        justifyContent: "flex-start",
      }}
    >
      {/* <Box sx={{ width: "auto" }}> */}
      {slotsContextData && (
        <UserInformationComponent
          showPdfUrl={showPdfUrl}
          setShowPdfUrl={setShowPdfUrl}
          slotsContextData={slotsContextData}
          checkUser={checkUser}
          feedbackFocused={feedbackFocused}
          setFeedbackFocused={setFeedbackFocused}
        />
      )}
      {checkUser === "mentor" ? (
        <>
          {(studentJoined || slotsContextData.student_join_time !== null) &&
          ["streaming", "preview", "meeting", "leave"].includes(
            window.location.pathname.split("/")[1]
          ) ? (
            <Feedback
              hmsActions={hmsActions}
              slotsContextData={slotsContextData}
              setSlotsContextData={setSlotsContextData}
              checkUser={checkUser}
              setShowPdfUrl={setShowPdfUrl}
              studentJoined={studentJoined}
              feedbackFocused={feedbackFocused}
              setFeedbackFocused={setFeedbackFocused}
              handleEndSessionYes={handleEndSessionYes}
            />
          ) : isRoomJoinCalled &&
            !(studentJoined || slotsContextData.student_join_time !== null) &&
            ["streaming", "preview", "meeting", "leave"].includes(
              window.location.pathname.split("/")[1]
            ) ? (
            <>
              <Typography sx={{ color: Color.accent, marginTop: "2.5rem" }}>
                Waiting for the student to join...
              </Typography>
            </>
          ) : ["/FeedbackOverview", "/FeedbackOverview/offline"].includes(
              window.location.pathname
            ) ? (
            <Feedback
              hmsActions={hmsActions}
              slotsContextData={slotsContextData}
              setSlotsContextData={setSlotsContextData}
              checkUser={checkUser}
              setShowPdfUrl={setShowPdfUrl}
              studentJoined={studentJoined}
              feedbackFocused={feedbackFocused}
              setFeedbackFocused={setFeedbackFocused}
              handleEndSessionYes={handleEndSessionYes}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <StudentNotes
            slotsContextData={slotsContextData}
            studentJoined={studentJoined}
            feedbackFocused={feedbackFocused}
            setFeedbackFocused={setFeedbackFocused}
            handleEndSessionYes={handleEndSessionYes}
            isRoomJoinCalled={isRoomJoinCalled}
          />
        </>
      )}
    </Box>
  );
};

export default DocumentsAndFeedback;
