import React, { createContext, useEffect, useState } from "react";

export const UnderMaintenanceContext = createContext();

export const UnderMaintenanceProvider = ({ children }) => {
  const [underMaintenanceCalled, setUnderMaintenanceCalled] = useState(false);
  useEffect(() => {
    if (underMaintenanceCalled) {
      const timer = setTimeout(() => {
        window.location.reload(); // Reload the page after 10 seconds
      }, 10000);

      return () => clearTimeout(timer); // Cleanup timeout on unmount
    }
  }, [underMaintenanceCalled]);
  return (
    <UnderMaintenanceContext.Provider
      value={{ underMaintenanceCalled, setUnderMaintenanceCalled }}
    >
      {children}
    </UnderMaintenanceContext.Provider>
  );
};
