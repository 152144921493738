import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import HeadsetIcon from "@mui/icons-material/Headset";
import HeadsetOffIcon from "@mui/icons-material/HeadsetOff";
import { Box } from "@mui/material";
import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";
import { useHMSActions } from "@100mslive/react-sdk";
import { Header } from "../../../components/Header";
import { ActivityLoader } from "../../../components/Loader/Loader";
import { SlotsContext } from "../../../AppContext";
import { UnderMaintenanceContext } from "../../../UnderMaintenanceContext";
import DocumentsAndFeedback from "../../DocumentsAndFeedback/DocumentsAndFeedback";
import BackendDownErrorHandling from "../../Reusables/BackendDownErrorHandling/BackendDownErrorHandling";
import AudioPlayer from "../FeedbackReview/AudioPlayer";
import Service from "../../../services/httpService";
export const Time = createContext();
const FeedbackOverview = () => {
  // const navigate = useNavigate();
  const hmsActions = useHMSActions();
  const { underMaintenanceCalled, setUnderMaintenanceCalled } = useContext(
    UnderMaintenanceContext
  );
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const services = new Service(setUnderMaintenanceCalled);
  const slot = JSON.parse(window.sessionStorage.getItem("slot"));
  const queryParams = new URLSearchParams(location.search);
  const { slotsContextData, setSlotsContextData } = useContext(SlotsContext);

  const checkUser = Cookies.get("user_type");
  const studentData = location.state?.studentData;
  console.log(" location.state queryParams:", location.state, queryParams);
  console.log("slot context in feedback overview:", slotsContextData);

  const AudioBox = ({ recordingUrl }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          gap: "1rem",
        }}
      >
        {recordingUrl ? (
          <>
            <HeadsetIcon
              sx={{
                width: "200px",
                height: "200px",
                color: "rgba(188, 188, 188, 1)",
              }}
            />
            <AudioPlayer url={recordingUrl} />
          </>
        ) : (
          <>
            <HeadsetOffIcon
              sx={{
                width: "200px",
                height: "200px",
                color: "rgba(188, 188, 188, 1)",
              }}
            />
            <AudioPlayer />
          </>
        )}
      </Box>
    );
  };

  const getDataForOffline = async () => {
    try {
      const res = await services.get(
        "/os/mentor/osOrderDetails?order_id=" + queryParams.get("id")
      );
      console.log("res of get order:", res);
      console.log("/os/mentor/osOrderDetails?order_id=");
      let tempData = {
        ...res.data[0],
      };
      console.log("CONTEXT temp 1", tempData);
      tempData["other_docs"] = tempData["other_docs"]
        ? JSON.parse(tempData["other_docs"])
        : [];
      tempData["resume"] = JSON.parse(tempData["resume"]);
      tempData["student_availability_proof"] = JSON.parse(
        tempData["student_availability_proof"]
      );
      console.log("CONTEXT temp", tempData);
      setSlotsContextData(tempData);
      return res.data[0];
    } catch (err) {
      console.log("error:", err);
    }
  };
  const fetchGetRequest = async () => {
    try {
      setLoading(true);
      if (
        window.location.pathname === "/FeedbackOverview/offline" &&
        [undefined, null].includes(slotsContextData)
      ) {
        location?.state?.studentData
          ? setSlotsContextData({
              ...location?.state?.studentData,
              id: location?.state?.studentData.id,
            })
          : await getDataForOffline();
      } else if (window.location.pathname !== "/FeedbackOverview/offline") {
        console.log("i am here online");
        const res = await services.get(`/superadmin/order?order_id=${slot.id}`);
        window.sessionStorage.setItem("slot", JSON.stringify(slot));
        console.log("updating the session", res);
        const session_guidelines = res.session_guidelines;
        setSlotsContextData({
          ...res.data[0],
          session_guidelines: session_guidelines,
          duration: res.sku_info.duration,
          sku_name: res.sku_info.sku_name,
          mentor_first_name: res.mentor_info.first_name,
          mentor_last_name: res.mentor_info.last_name,
          student_first_name: res.student_info.first_name,
          student_last_name: res.student_info.last_name,
        });
      }
      setLoading(false);
    } catch (err) {
      console.error("helllo", err.response.data.message);
    }
  };

  useEffect(() => {
    fetchGetRequest();
    return () => {
      hmsActions.leave();
    };
  }, []);

  return (
    <>
      {underMaintenanceCalled ? (
        <BackendDownErrorHandling />
      ) : (
        <>
          {(slotsContextData &&
            window.location.pathname === "/FeedbackOverview/offline") ||
          (checkUser === "mentor" &&
            slotsContextData &&
            slotsContextData.session_guidelines !== undefined) ? (
            <Box
              className="hide-scrollbar"
              sx={{
                ...(window.location.pathname === "/FeedbackOverview/offline" &&
                  {
                    // paddingLeft: window.innerWidth > 1500 ? "0px" : "8px",
                    // paddingRight: window.innerWidth > 1500 ? "0px" : "8px",
                  }),
                width: "100%",
                height: "100vh",
              }}
            >
              <Box
                sx={{
                  width: "1460px",
                  justifyContent: checkUser === "mentor" ? "space-around" : "",
                  background:
                    window.location.pathname === "/FeedbackOverview" ||
                    window.location.pathname === "/FeedbackOverview/offline"
                      ? "rgba(255, 255, 255, .8)"
                      : "#1B1A1A",
                  display: "flex",
                  flexDirection: "column",
                  ...(window.location.pathname ===
                    "/FeedbackOverview/offline" && {
                    gap: "0px",
                    paddingLeft: window.innerWidth > 1500 ? "0px" : "8px",
                    paddingRight: window.innerWidth > 1500 ? "0px" : "8px",
                  }),
                  ...(window.location.pathname === "/FeedbackOverview" && {
                    gap: "1rem",
                  }),
                  margin: "0 auto",
                  height: "100vh",
                }}
              >
                <Header />
                <Box
                  className="hide-scrollbar"
                  sx={{
                    width: "1460px",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "row",
                    gap: "1.5rem",
                    flex: 1,
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      width: "70%",
                      position: "relative",
                    }}
                  >
                    <DocumentsAndFeedback
                      hmsActions={hmsActions}
                      slotsContextData={slotsContextData}
                      setSlotsContextData={setSlotsContextData}
                      checkUser={checkUser}
                      studentData={studentData}
                    />
                  </Box>
                  {window.location.pathname === "/FeedbackOverview/offline" ? (
                    <Box
                      sx={{
                        width: "30%",
                        margin: "0 auto",
                        marginRight: "10px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        width: "30%",
                        margin: "0 auto",
                        marginRight: "10px",
                      }}
                    >
                      <Box>
                        <AudioBox
                          recordingUrl={slotsContextData.recording_url}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ) : loading ? (
            <ActivityLoader show={loading} />
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default FeedbackOverview;
