import React from "react";
import { Typography } from "@mui/material";
const BackendDownErrorHandling = () => {
  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <div
        style={{
          display: "Flex",
          flexDirection: "column",
          margin: "auto",
          alignItems: "center",
          marginTop: "15%",
          overflowY: "hidden",
        }}
      >
        <img
          src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/UnderMaintainanceGroup.png"
          style={{ width: "200px", height: "200px" }}
        />
        <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
          Maintenance in Progress
        </Typography>
        <Typography>
          We’re enhancing our platform to serve you better, Please come back
          later!
        </Typography>
      </div>
    </div>
  );
};

export default BackendDownErrorHandling;
