import { Flex, VerticalDivider } from "@100mslive/react-ui";
import { Box, Button, Modal as MUIModal, Typography, ButtonBase } from "@mui/material";
import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import TimeService from "../../services/epochTime";
import { calculateEpochTimeInLocalTime } from "../../services/specialCharReplace";
import { ParticipantCount } from "./ParticipantList";
import { StreamActions } from "./StreamActions";
// import { ChatToggle } from "../Footer/ChatToggle";
import TimeSection from "../TimeSection/TimeSection";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Color, styles, DivCenter } from "../../../GlobalStyles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import { useHMSActions, useHMSNotifications } from "@100mslive/react-sdk";
import { SlotsContext } from "../../AppContext";
import Service from "../../services/httpService";
import Alert from "../Alert/Alert";
import { useNavigate } from "react-router-dom";
import { TimerOutlined } from "@mui/icons-material";
import { convertToUTCMilliseconds } from "../../services/epochTime";
import { ChatToggle } from "../Footer/ChatToggle";
import { ProvideFeedback } from "../../AppContext";
export const ConferencingHeader = ({ isPreview, studentJoined, handleEndSessionYes }) => {
  const slot = JSON.parse(window.sessionStorage.getItem("slot"))
  const { slotsContextData } = useContext(SlotsContext);
  const { provideFeedback, setProvideFeedback } = useContext(ProvideFeedback);
  const hmsActions = useHMSActions();
  console.log("slotsContextData", slotsContextData);
  console.log("provideFeedback:", provideFeedback)
  console.log("isPreview", isPreview);
  console.log("studentJoined????",);
  const checkUser = Cookies.get('user_type')
  const time = new TimeService()
  const services = new Service()
  const [viewGuidelinesShow, setViewGuidelinesShow] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [extendedTime, setExtendedTime] = useState(0);
  const [timeRemainingStudent, setTimeRemainingStudent] = useState(0);
  const [markAbsentButton, setMarkAbsentButton] = useState(false);
  const [markAbsentModal, setMarkAbsentModal] = useState(false);
  const [markAbsentModalClick, setMarkAbsentModalClick] = useState(false);
  const [extendedTimeModal, setExtendedTimeModal] = useState(false);
  const navigate = useNavigate();
  const { isRoomJoinCalled } = hmsActions;
  const { hmsSDKPeers } = hmsActions;
  const notification = useHMSNotifications();
  console.log("markAbsentModalClick", markAbsentModalClick, markAbsentButton);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    gap: "8px",
    transform: "translate(-50%, -50%)",
    width: "auto",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "20px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
    p: 4,
    minWidth: "650px",
  };

  useEffect(() => {
    const handleEffect = () => {
      if (checkUser === "student b2c" || checkUser === "student b2b") {
        const now = Date.now();
        window.sessionStorage.setItem("student_join_time", now);
      }

      console.log("pathname:", window.location.pathname);
      if (
        ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1])
      )
        setViewGuidelinesShow(true);
    };
    handleEffect();
  }, []);

  const startTimer = (mentor_start_time, type, slot_difference) => {
    console.log("mentor_start_time", mentor_start_time);
    let interval;
    if (type === "time remaining") {
      const skuDuration = slot_difference ?? slot?.sku_duration;
      const durationInMinutes = parseInt(skuDuration?.match(/\d+/)[0]);
      const durationInMillis = durationInMinutes * 1000 * 60;
      const mentorJoinInMillis = slotsContextData?.mentor_join_time ||
        parseInt(window.sessionStorage.getItem("mentor_join_time")) ||
        mentor_start_time;
      console.log("mentorJoinInMillis", mentorJoinInMillis);
      const now = Date.now();
      const endTime = mentorJoinInMillis + durationInMillis;
      const initialTimeRemaining = endTime - now;
      console.log("initialTimeRemaining", initialTimeRemaining);
      setTimeRemaining(initialTimeRemaining);
    } else {
      setExtendedTime(mentor_start_time)
    }

    interval = setInterval(() => {
      if (type === "time remaining") {
        setTimeRemaining((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1000;
          } else {
            clearInterval(interval);
            onFinishTimeRemaining();
            return 0;
          }
        });
      } else if (type === "extended time") {
        setExtendedTime((prevTime) => {
          if (prevTime >= 0 && prevTime < 15 * 60 * 1000) {
            const remainingTime = prevTime + 1000;

            // Show modal at 10 minutes into extendedTime
            if (remainingTime >= 10 * 60 * 1000 && remainingTime < 11 * 60 * 1000) {
              if (checkUser === "mentor" && window.location.pathname !== "/FeedbackOverview" && window.location.pathname !== "/FeedbackOverview/offline") {
                if (!window.sessionStorage.getItem("extendedModalShown")) {
                  setExtendedTimeModal(true);
                  window.sessionStorage.setItem("extendedModalShown", "true");
                }
              }
            }

            return remainingTime;
          } else {
            clearInterval(interval);
            handleEndSessionYes();
            return 0;
          }
        });
      }

    }, 1000);
    console.log("interval", interval);
    return () => clearInterval(interval);
  };

  const alertResponseExtendedModal = (data) => {
    setExtendedTimeModal(false)

  }

  useEffect(() => {
    if ((isRoomJoinCalled && slotsContextData.mentor_join_time === null) || (isRoomJoinCalled && slotsContextData.student_join_time === null) || (!isRoomJoinCalled && (slotsContextData.mentor_join_time || slotsContextData.student_join_time))) {
      const peerId = Object.keys(hmsSDKPeers)[0];
      const peer = hmsSDKPeers[peerId];
      console.log("peer details", peer);
      console.log("slotsContextData in ue00:", slotsContextData)
      let mentorStartTime = slotsContextData.mentor_join_time || parseInt(window.sessionStorage.getItem("mentor_join_time"));
      console.log("mentorStartTime", mentorStartTime);
      if (window.location.pathname === "/FeedbackOverview/offline")
        startTimer(mentorStartTime, "time remaining", "30");
      else
        startTimer(mentorStartTime, "time remaining");
    }
  }, [slotsContextData.mentor_join_time, slotsContextData.student_join_time]);

  useEffect(() => {
    if (checkUser === "student b2c" || checkUser === "student b2b") {
      const now = Date.now();
      const student_join_time =
        slot?.student_join_time ||
        parseInt(window.sessionStorage.getItem("student_join_time")) ||
        now; // Replace this with your actual value
      const mentorJoinInMillis = slot?.mentor_join_time
        ? slot?.mentor_join_time
        : parseInt(window.sessionStorage.getItem("mentor_join_time"));
      console.log("duration jbfe", mentorJoinInMillis);
      console.log("now", now);
      const endTime = student_join_time - mentorJoinInMillis;
      console.log("lost time", student_join_time, mentorJoinInMillis);
      // const initialTimeRemaining = endTime - now;
      setTimeRemainingStudent(endTime);
    }
  }, []);

  console.log("extendedTime", extendedTime);

  const onFinishTimeRemaining = () => {
    setTimeRemaining(0);
    // setExtendedTime(15 * 60 * 1000);
    if (["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1])) {
      setExtendedTime(0);
      setTimeout(() => {
        startTimer(0, "extended time");
      }, 100);
    }

  };

  const formatTime = milliseconds => {
    console.log("what am i getting", milliseconds);
    if (milliseconds < 0) {
      return "00:00"; // or any default value you prefer when the time is negative
    }
    const minutes = Math.floor(milliseconds / 60000); // Convert milliseconds to minutes
    const seconds = Math.floor((milliseconds % 60000) / 1000); // Remaining seconds
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const onFinish = () => {
    // console.log("studentJoined || slotsContextData.student_join_time !== null", studentJoined || slotsContextData.student_join_time !== null);

    const hasStudentJoined = studentJoined || slotsContextData.student_join_time !== null;
    setMarkAbsentButton(!hasStudentJoined);

    if (!hasStudentJoined && window.sessionStorage.getItem("markAbsentModalShown") !== "true" && window.location.pathname !== "/FeedbackOverview") {
      setMarkAbsentModal(true);
      window.sessionStorage.setItem("markAbsentModalShown", "true");
    }

    window.sessionStorage.setItem("mentor_student_join_alert", "true");

  };


  useEffect(() => {
    if (checkUser === "mentor" && !markAbsentModal) {
      const intervalId = setInterval(() => {
        const storedMentorJoinTime = parseInt(window.sessionStorage.getItem("mentor_join_time"));
        const mentorJoinTime = slotsContextData?.mentor_join_time || storedMentorJoinTime;
        const joinDeadline = mentorJoinTime ? mentorJoinTime + 450000 : Date.now() + 450000;
        const currentTime = Date.now();
        const timeLeft = Math.round((joinDeadline - currentTime) / 1000);

        if (timeLeft <= 0) {
          console.log("checking once or twice");
          onFinish();
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [checkUser, slotsContextData?.mentor_join_time, studentJoined, markAbsentModal]);

  useEffect(() => {
    if (studentJoined) {
      setMarkAbsentModal(false);
      setMarkAbsentModalClick(false)
    }
  }, [studentJoined]);


  const handleMarkAbsent = () => {
    setMarkAbsentModalClick(true);
  };

  const handleAlertMessageModal = (data) => {
    if (data === "Yes") {
      setMarkAbsentModal(false)
      setMarkAbsentModalClick(true)
    } else {
      setMarkAbsentModal(false)
    }
  }

  const alertResponse = async data => {
    console.log("daphney:", data);
    if (data === "Yes") {
      let body = {
        order_id: slot.id,
        is_absent: 1,
      };
      try {
        await services.put(`/scheduling/session/endSession`, body);
        sessionStorage.clear();
        navigate(`/`);
        // window.location.reload();
      } catch (err) {
        console.log(err.message || err.response.data.message);
      }
      setMarkAbsentModal(false);
      setMarkAbsentModalClick(false)
    } else {
      // sessionStorage.setItem('mark_absent', 'No');
      setMarkAbsentButton(false)
      setMarkAbsentModal(false);
      setMarkAbsentModalClick(false)
    }
  };
  console.log("timeRemaining", timeRemaining);
  return (
    <div style={{ background: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? "#1B1A1A" : Color.feedbackColor, }} className="hide-scrollbar">
      <Card sx={{
        maxWidth: "100%", position: "relative", height: "100%",
        background: window.location.pathname === "/FeedbackOverview/offline" ? Color.white : ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? "#1B1A1A" : Color.feedbackColor,
        boxShadow: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? "none" : "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", ...(window.location.pathname === "/FeedbackOverview/offline" && {borderRadius:"0px",borderTopWidth: "0px",
          borderLeftWidth: "0px",
          borderRightWidth: "0px",})
      }} variant="outlined">
        <CardContent sx={{ background: window.location.pathname === "/FeedbackOverview/offline" ? Color.offlineBackgroundColor : null, padding: "12px", '&:last-child': { paddingBottom: "12px" } }}>
          <Flex
            justify="between"
            align="center"
          >
            {(studentJoined || slotsContextData.student_join_time !== null) ?
              <Flex css={{ ml: "10px" }}>
                {checkUser === "mentor" ? (
                  <Typography
                    variant="h6"
                    style={{ textAlign: "left", fontSize: "16px", fontWeight: "medium", color: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? Color.white : Color.blueBlack }}
                  >
                    {slotsContextData.sku_name} of {slotsContextData.student_first_name ?? slotsContextData.student_name} {slotsContextData.student_last_name}
                  </Typography>
                ) : (
                  <Typography
                    variant="h6"
                    style={{ textAlign: "left", fontSize: "16px", fontWeight: "medium", color: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? Color.white : Color.blueBlack }}
                  >
                    {slotsContextData.sku_name} with {slotsContextData.mentor_first_name} {slotsContextData.mentor_last_name}
                  </Typography>
                )}
                <VerticalDivider css={{ mx: "$8", color: window.location.pathname === "/FeedbackOverview/offline" ? Color.blueBlack : Color.neutralMidGrey }} />
                <Typography style={{ color: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? Color.neutralMidGrey : Color.blueBlack, fontSize: "16px", margin: 'auto' }}>{`${slotsContextData?.college_name}, ${slotsContextData?.user_group_name}, ${slotsContextData?.batch_type_name}`}</Typography>
                <VerticalDivider css={{ mx: "$8", color: window.location.pathname === "/FeedbackOverview/offline" ? Color.blueBlack : Color.neutralMidGrey }} />
                <Typography style={{ color: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? Color.neutralMidGrey : Color.blueBlack, fontSize: "16px", margin: 'auto' }}>{`${time.convertEpochToDate(slotsContextData?.start_time)}, ${time.convertEpochToTime(slotsContextData?.start_time)}`}</Typography>
              </Flex>
              :
              <Box sx={{ ml: "10px" }}>
                {checkUser === "mentor" ? (
                  <Typography
                    variant="h6"
                    style={{ textAlign: "left", fontSize: "16px", fontWeight: "medium", color: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? Color.white : Color.blueBlack }}
                  >
                    {slotsContextData.sku_name} of {slotsContextData.student_first_name} {slotsContextData.student_last_name}
                  </Typography>
                ) : (
                  <Typography
                    variant="h6"
                    style={{ textAlign: "left", fontSize: "16px", fontWeight: "medium", color: ["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) ? Color.white : Color.blueBlack }}
                  >
                    {slotsContextData.sku_name} with {slotsContextData.mentor_first_name} {slotsContextData.mentor_last_name}
                  </Typography>
                )}
                <Box sx={{ display: "flex", flexDirection: "row", gap: "6rem", justifyContent: "left", alignItems: "center" }}>
                  <Box>
                    <Typography style={{ color: Color.neutralMidGrey, fontSize: "16px", margin: 'auto' }}>{`${slotsContextData?.college_name}, ${slotsContextData?.user_group_name}, ${slotsContextData?.batch_type_name}`}</Typography>
                    <Typography style={{ color: Color.neutralMidGrey, fontSize: "16px", margin: 'auto' }}>{`${time.convertEpochToDate(slotsContextData?.start_time)}, ${time.convertEpochToTime(slotsContextData?.start_time)}`}</Typography>
                  </Box>
                  <Box sx={{ margin: 'auto auto 0 auto' }}>
                    {checkUser === "mentor" && markAbsentButton && window.location.pathname !== "/FeedbackOverview" && (
                      <Button
                        variant="outlined"
                        onClick={() => handleMarkAbsent()}
                        sx={{
                          ...styles.button_styles.outlined,
                          ml: "10px",
                          color: Color.red,
                          width: "150px",
                          height: "35px",
                          border: "1px solid red",
                          background: "transparent",
                          borderRadius: "8px",
                          p: "10px",
                          "&:hover": {
                            color: Color.red,
                            border: "1px solid red",
                            background: "transparent",
                            borderRadius: "8px",
                          },
                        }}
                      >
                        MARK ABSENT
                      </Button>
                    )}
                  </Box>
                </Box>
              </Box>}
            {(["streaming", "preview", "meeting", "leave"].includes(window.location.pathname.split("/")[1]) || (window.location.pathname === "/FeedbackOverview/offline" && !provideFeedback)) &&
              <Flex
                align="center"
                css={{
                  position: "absolute",
                  right: "$10",
                  gap: "$4",
                }}
              >
                <Flex align="center">
                  <TimerOutlined style={{ color: window.location.pathname === "/FeedbackOverview/offline" ? Color.blueBlack : Color.white }} sx={{ mr: 1 }} />
                  <Typography style={{ color: window.location.pathname === "/FeedbackOverview/offline" ? Color.blueBlack : Color.white, fontSize: "16px" }} sx={{ mr: 1 }}>
                    Time left :
                  </Typography>
                  {checkUser === "mentor" ?
                    <Typography style={{ color:window.location.pathname === "/FeedbackOverview/offline" ? Color.offlineGreenColor : Color.green, fontSize: "16px" }}>
                      {slotsContextData.mentor_join_time === null ? "30:00" : timeRemaining ? formatTime(timeRemaining) : <span style={{ color: Color.meetRed }}>{"-"} {formatTime(extendedTime)}</span>}
                    </Typography> :
                    <Typography style={{ color: Color.green, fontSize: "16px" }}>
                      {formatTime(timeRemaining)}
                    </Typography>
                  }
                </Flex>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  {checkUser !== "mentor" &&
                    <Flex align="center">
                      <TimerOutlined style={{ color: Color.red, fontSize: "16px" }} sx={{ mr: 1 }} />
                      <Typography variant="h6" color={Color.red} sx={{ mr: 1 }}>
                        Lost Time :
                      </Typography>
                      <Typography variant="h6" style={{ color: Color.red, fontSize: "16px" }}>
                        {timeRemainingStudent ? formatTime(timeRemainingStudent) : "00:00"}
                      </Typography>
                    </Flex>}
                    {window.location.pathname === "/FeedbackOverview/offline" ? null : <ChatToggle checkUser={checkUser} />}
                  
                </Box>
              </Flex>
            }

          </Flex>
        </CardContent>
      </Card>
      <div>
        <MUIModal
          open={viewGuidelinesShow}
          onClose={() => setViewGuidelinesShow(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between ",
              }}
            >
              <Typography id="modal-modal-title" component="h2" fontSize={24}>
                Guidelines
              </Typography>
            </div>
            <Typography
              id="modal-modal-description"
              color={Color.neutralMidGrey}
              sx={{ marginBottom: ".5rem" }}
            >
              Kindly follow the below guidelines while attending the session
            </Typography>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <div style={{ border: `1px solid ${Color.neutralMidGrey}`, padding: "8px", borderRadius: "4px" }}>
                <Typography
                  id="modal-modal-description"
                  color={Color.primary1}
                  sx={{ paddingBottom: "8px", fontWeight: "medium" }}
                >
                  Interview Environment
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "8px"
                    }}
                  >
                    <div >
                      <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Group+187.png" />
                    </div>
                    <div >
                      <CheckIcon sx={{ color: "#08AB3F" }} />
                    </div>
                    <div style={{ width: "80%", justifyContent: "left" }}>
                      <Typography id="modal-modal-description" >
                        Quiet room without disturbance, blank background as much
                        as possible
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      flexDirection: "row",
                      gap: "8px"
                    }}
                  >
                    <div >
                      <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/camera.png" />
                    </div>
                    <div >
                      <CheckIcon sx={{ color: "#08AB3F" }} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <Typography id="modal-modal-description">
                        Video must be on
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ border: `1px solid ${Color.neutralMidGrey}`, padding: "8px", borderRadius: "4px" }}>
                <Typography
                  id="modal-modal-description"
                  color={Color.primary1}
                  sx={{ paddingBottom: "8px", fontWeight: "medium" }}
                >
                  Dress Code
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "8px"
                    }}
                  >
                    <div >
                      <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Group+184.png" />
                    </div>
                    <div >
                      <CheckIcon sx={{ color: "#08AB3F" }} />
                    </div>
                    <div style={{ width: "80%", justifyContent: "left" }}>
                      <Typography id="modal-modal-description" >
                        Formals only (no need of blazer or tie)
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      flexDirection: "row",
                      gap: "8px"
                    }}
                  >
                    <div >
                      <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Group+184+(1).png" />
                    </div>
                    <div >
                      <ClearIcon sx={{ color: "red" }} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <Typography id="modal-modal-description">
                        Avoid T-shirt even if it is collared
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ border: `1px solid ${Color.neutralMidGrey}`, padding: "8px", borderRadius: "4px" }}>
                <Typography
                  id="modal-modal-description"
                  color={Color.primary1}
                  sx={{ paddingBottom: "8px", fontWeight: "medium" }}
                >
                  Body Language
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      gap: "8px"
                    }}
                  >
                    <div >
                      <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/interview.png" />
                    </div>
                    <div >
                      <CheckIcon sx={{ color: "#08AB3F" }} />
                    </div>
                    <div style={{ width: "80%", justifyContent: "left" }}>
                      <Typography id="modal-modal-description" >
                        Exactly similar to F2F interview
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      flexDirection: "row",
                      gap: "8px"
                    }}
                  >
                    <div >
                      <img src="https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/sofa.png" />
                    </div>
                    <div >
                      <ClearIcon sx={{ color: "red" }} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <Typography id="modal-modal-description">
                        Avoid doing session on a bed or flat couches
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <ButtonBase>
                <Box
                  onClick={() => setViewGuidelinesShow(false)}
                  sx={{
                    ...DivCenter,
                    borderRadius: "8px",
                    background: Color.primary1,
                    color: Color.white,
                    fontSize: "14px",
                    height: "40px",
                    width: "150px",
                    minWidth: "100px",
                    p: "10px",
                  }}
                >
                  Got it
                </Box>
              </ButtonBase>
            </div>
          </Box>
        </MUIModal>
      </div>
      <Alert
        alert={markAbsentModal}
        setAlert={setMarkAbsentModal}
        body={<p>Oops! It looks like the student hasn't joined the meeting.<br /> Do you want to mark this student as absent?</p>}
        noButton="Wait a bit"
        yesButton="Mark Student Absent"
        responseAlert={handleAlertMessageModal}
        onHide={() => setMarkAbsentModal(false)}
      />
      <Alert
        alert={markAbsentModalClick}
        setAlert={setMarkAbsentModalClick}
        body="Are you sure you would like to mark the student absent?"
        noButton="No"
        yesButton="Yes"
        responseAlert={alertResponse}
        onHide={() => setMarkAbsentModalClick(false)}
      />
      {window.location.pathname !== "/FeedbackOverview" && (
        <Alert
          alert={extendedTimeModal}
          setAlert={setExtendedTimeModal}
          heading="ATTENTION"
          body={<p>Meeting room closes in 5 mins<br />
            Kindly wrap up this session in the next 5 minutes as the meeting room is about to end.</p>}
          responseAlert={alertResponseExtendedModal}
          onHide={() => setExtendedTimeModal(false)}
          origin="extendedTime"
        />
      )}
    </div>
  );
};
