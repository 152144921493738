import React, { useContext, useEffect, useState } from "react";
import { Circle, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { Color, DivCenter, styles } from "../../../../GlobalStyles";
import { SlotsContext } from "../../../AppContext";
import Service from "../../../services/httpService";
import {
  get_specialCharReplace,
  send_specialCharReplace,
} from "../../../services/specialCharReplace";
import "../StudentNotes/StudentNotes.css";

const StudentNotes = props => {
  const slot = JSON.parse(window.sessionStorage.getItem("slot"));
  const [noteData, setNoteData] = useState({ bullets: [""] });
  const [savedBullets, setSavedBullets] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const services = new Service();
  console.log("isEditing", isEditing);
  const { feedbackFocused, setFeedbackFocused } = props;
  const { slotsContextData } = useContext(SlotsContext);

  useEffect(() => {
    try {
      const session_noteData = JSON.parse(
        window.sessionStorage.getItem("NoteData")
      );
      const session_order_id = parseInt(
        window.sessionStorage.getItem("session_order_id")
      );
      if (session_noteData && session_order_id === slot.id) {
        setSavedBullets(session_noteData.bullets);
      }
    } catch (error) {
      console.error("Error loading session data:", error);
    }
  }, [slot.id]);

  const handleSubmit = async newSavedBullets => {
    let body = {
      order_id: slot.id,
      notes: {
        bullets: newSavedBullets.map(data => send_specialCharReplace(data)),
      },
    };
    try {
      const res = await services.post(`/studentModule/studentNotes`, body);
      console.log("res", res);
    } catch (err) {
      console.error("Error submitting notes:", err);
      window.alert(err.response?.data?.message || "An error occurred");
    }
  };

  const handleFeedbackClick = () => {
    setFeedbackFocused(true);
  };

  const handleBackgroundClick = () => {
    setFeedbackFocused(false);
  };

  const handleCloseClick = event => {
    event.stopPropagation();
    setFeedbackFocused(false);
  };

  const handleBulletChange = (index, value) => {
    setNoteData(prevNoteData => {
      const newBullets = [...prevNoteData.bullets];
      newBullets[index] = value;
      return { bullets: newBullets };
    });
  };

  const handleKeyPress = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveBullet(index);
      setIsEditing(null);
    }
  };

  const saveBullet = index => {
    setIsEditing(null);
    const bullet = get_specialCharReplace(noteData.bullets[index]);
    if (bullet.trim() !== "") {
      setSavedBullets(prevSavedBullets => {
        const newSavedBullets = [...prevSavedBullets, bullet];
        handleSubmit(newSavedBullets);
        return newSavedBullets;
      });
      setNoteData(prevNoteData => {
        const newBullets = [...prevNoteData.bullets];
        newBullets[index] = "";
        return { bullets: newBullets };
      });
    }
  };

  const handleSaveClick = e => {
    noteData.bullets.forEach((bullet, index) => {
      if (bullet.trim() !== "") {
        saveBullet(index);
      }
    });
    e.stopPropagation();
    setFeedbackFocused(false);
    setIsEditing(null);
  };

  const handleEditClick = index => {
    setIsEditing(index);
  };

  const handleEditChange = (index, value) => {
    const newBullets = [...savedBullets];
    newBullets[index] = value;
    setSavedBullets(newBullets);
  };

  const handleEditKeyPress = (e, index) => {
    if (e.key === "Enter") {
      e.preventDefault();
      saveEditedBullet(index);
    }
  };

  const saveEditedBullet = index => {
    setIsEditing(null);
    handleSubmit(savedBullets);
  };

  console.log(
    "not able to charge",
    noteData.bullets[0] === "" || isEditing !== null
  );
  console.log(
    "saved bullets",
    savedBullets,
    noteData,
    noteData.bullets[0].length
  );
  console.log(
    "condition",
    noteData.bullets[0].length === 0 || isEditing === null
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          zIndex: feedbackFocused ? 10 : 1,
          top: `${feedbackFocused ? "35%" : "25%"}`,
        }}
        onClick={handleFeedbackClick}
        className={
          window.location.pathname === "/FeedbackOverview"
            ? `feedback-wrapper ${feedbackFocused ? "focused" : ""}`
            : `feedback-wrapper-black ${feedbackFocused ? "focused" : ""}`
        }
      >
        <Box
          sx={{
            ...DivCenter,
            flexDirection: "column",
            width: "100%",
            paddingLeft: "0px",
            justifyContent: "flex-start",
            gap: "1.5rem",
          }}
        >
          {slotsContextData.student_join_time !== null ? (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: feedbackFocused
                    ? "rgba(0, 0, 0, 0.1)"
                    : "transparent",
                  padding: feedbackFocused ? "16px 8px 16px 8px" : "0px",
                  borderRadius: "6px",
                  ...(!feedbackFocused && { gap: ".5rem" }),
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      color:
                        window.location.pathname === "/FeedbackOverview" &&
                        !feedbackFocused
                          ? Color.blueBlack
                          : Color.white,
                      textAlign: "left",
                      width: "100%",
                      fontSize: "20px",
                      ...(!feedbackFocused && {
                        marginTop: ".5rem",
                        marginBottom: ".3rem",
                      }),
                    }}
                  >
                    {savedBullets.length === 0 ? "Take Notes" : "Your Notes"}
                  </Typography>
                  {feedbackFocused && (
                    <IconButton
                      onClick={handleCloseClick}
                      sx={{
                        cursor: "pointer",
                        color: Color.white,
                        padding: "0px",
                      }}
                    >
                      <Close />
                    </IconButton>
                  )}
                </Box>
                <Box
                  sx={{
                    ...DivCenter,
                    width: "100%",
                    height: "auto",
                    justifyContent: "left",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  {savedBullets.length === 0 && !feedbackFocused ? (
                    <>
                      <Box
                        sx={{
                          ...DivCenter,
                          justifyContent: "flex-start",
                          width: "100%",
                          height: "40px",
                          border:
                            window.location.pathname === "/FeedbackOverview" &&
                            !feedbackFocused
                              ? `1px solid ${Color.blueBlack}`
                              : window.location.pathname ===
                                  "/FeedbackOverview" && feedbackFocused
                              ? "none"
                              : `1px solid ${Color.white}`,
                          minHeight: "40px",
                          borderRadius: "12px",
                          p: "10px",
                          background: feedbackFocused
                            ? Color.white
                            : "transparent",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "left",
                            flexDirection: "column",
                            width: "100%",
                            height: "auto",
                            justifyContent: "left",
                          }}
                        >
                          <TextField
                            multiline
                            placeholder="Click to type your feedback"
                            variant="standard"
                            style={{
                              color:
                                window.location.pathname === "/FeedbackOverview"
                                  ? Color.blueBlack
                                  : "#fff",
                              border: "none",
                              width: "100%",
                              padding: 0,
                            }}
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                color: Color.blueBlack,
                              },
                            }}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : !feedbackFocused && savedBullets.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "left",
                        border: "1px solid rgba(107, 107, 107, 1)",
                        borderRadius: "10px",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          color: Color.white,
                          marginLeft: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        Click to type/view your note
                      </Typography>
                      <List sx={{ width: "100%" }}>
                        <ListItem
                          key={0}
                          sx={{ padding: "0px", paddingLeft: "8px" }}
                        >
                          <ListItemIcon sx={{ minWidth: "16px" }}>
                            <Circle
                              sx={{ fontSize: "8px", color: Color.white }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            sx={{ color: Color.white }}
                            primary={
                              <>
                                {savedBullets[0].length > 50
                                  ? `${get_specialCharReplace(
                                      savedBullets[0].slice(0, 50)
                                    )}... `
                                  : get_specialCharReplace(savedBullets[0])}
                                {savedBullets[0].length > 50 && (
                                  <Typography
                                    component="span"
                                    sx={{
                                      color: Color.primary1,
                                      cursor: "pointer",
                                    }}
                                    onClick={handleFeedbackClick}
                                  >
                                    Read more
                                  </Typography>
                                )}
                              </>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  ) : (
                    <>
                      <List sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            ...DivCenter,
                            width: "100%",
                            height: "auto",
                            gap: "4px",
                            justifyContent: "left",
                            flexDirection: "column",
                          }}
                        >
                          <Box
                            sx={{
                              ...DivCenter,
                              justifyContent: "flex-start",
                              width: "100%",
                              height: "auto",
                              border:
                                window.location.pathname ===
                                  "/FeedbackOverview" && !feedbackFocused
                                  ? `1px solid ${Color.blueBlack}`
                                  : window.location.pathname ===
                                      "/FeedbackOverview" && feedbackFocused
                                  ? "none"
                                  : `1px solid ${Color.white}`,
                              minHeight: "40px",
                              borderRadius: "12px",
                              p: "10px",
                              background: feedbackFocused
                                ? Color.white
                                : "transparent",
                              flexDirection: "column",
                            }}
                          >
                            {savedBullets.map((bullet, index) => (
                              <ListItem key={index} sx={{ padding: "0px" }}>
                                <ListItemIcon sx={{ minWidth: "16px" }}>
                                  <Circle
                                    sx={{
                                      fontSize: "8px",
                                      color: feedbackFocused
                                        ? Color.blueBlack
                                        : Color.white,
                                    }}
                                  />
                                </ListItemIcon>
                                {isEditing === index ? (
                                  <TextField
                                    multiline
                                    variant="standard"
                                    value={bullet}
                                    onChange={e =>
                                      handleEditChange(index, e.target.value)
                                    }
                                    onKeyPress={e =>
                                      handleEditKeyPress(e, index)
                                    }
                                    style={{
                                      color: feedbackFocused
                                        ? Color.blueBlack
                                        : "#fff",
                                      border: "none",
                                      width: "100%",
                                      padding: 0,
                                    }}
                                    InputProps={{
                                      disableUnderline: true,
                                      style: { color: Color.blueBlack },
                                    }}
                                  />
                                ) : (
                                  <ListItemText
                                    sx={{
                                      color: feedbackFocused
                                        ? Color.blueBlack
                                        : Color.white,
                                    }}
                                    primary={
                                      <Typography
                                        component="span"
                                        onClick={() => handleEditClick(index)}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        {get_specialCharReplace(bullet)}
                                      </Typography>
                                    }
                                  />
                                )}
                              </ListItem>
                            ))}
                            {feedbackFocused &&
                              noteData.bullets.map((bullet, index) => (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    alignItems: "left",
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "auto",
                                    justifyContent: "left",
                                  }}
                                >
                                  <TextField
                                    multiline
                                    placeholder="Click to type your feedback"
                                    variant="standard"
                                    value={get_specialCharReplace(bullet)}
                                    onChange={e =>
                                      handleBulletChange(index, e.target.value)
                                    }
                                    onKeyPress={e => handleKeyPress(e, index)}
                                    style={{
                                      color:
                                        window.location.pathname ===
                                        "/FeedbackOverview"
                                          ? Color.blueBlack
                                          : "#fff",
                                      border: "none",
                                      width: "100%",
                                      padding: 0,
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          <Circle
                                            sx={{
                                              fontSize: "8px",
                                              color: Color.neutralMidGrey,
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                      disableUnderline: true,
                                      style: { color: Color.blueBlack },
                                    }}
                                  />
                                </Box>
                              ))}
                          </Box>
                        </Box>
                      </List>
                      {feedbackFocused && (
                        <Button
                          variant="contained"
                          onClick={e => handleSaveClick(e)}
                          className="Rephrasebutton"
                          size="small"
                          sx={{
                            ...styles.button_styles.contained,
                            fontSize: "20px",
                            width: "25%",
                            height: "32px",
                            "&:disabled": {
                              background: Color.neutralLightGrey,
                              color: Color.neutralMidGrey,
                              boxShadow: "none",
                              width: "25%",
                              height: "32px",
                            },
                          }}
                          disabled={
                            noteData.bullets[0].length === 0 &&
                            isEditing === null
                          }
                        >
                          Save
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      {feedbackFocused && (
        <Box
          className="feedback-overlay"
          sx={{
            top: "max(6%, 50px)",
            ...(window.innerWidth > 1500 && {
              left: "2.3%",
              width: "max(65.8%, 780px)",
            }),
          }}
          onClick={handleBackgroundClick}
        />
      )}
    </Box>
  );
};

export default StudentNotes;
