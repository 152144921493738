import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Circle, Close } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { VerticalDivider } from "@100mslive/react-ui";
import Alert from "../../../components/Alert/Alert";
import { FeedbackModal } from "../../../components/Modal/CameraAndAudioModal";
import {
  Color,
  DivCenter,
  feedbackbackground,
  styles,
} from "../../../../GlobalStyles";
import { ProvideFeedback, SlotsContext } from "../../../AppContext";
import { PlaceholderModal } from "../../PlaceholderModal/PlaceholderModal";
import { DialogueBox } from "../../../services/DialogueBox";
import Service from "../../../services/httpService";
import { sort_by } from "../../../services/sortJSONinArray";
import {
  get_specialCharReplace,
  send_specialCharReplace,
} from "../../../services/specialCharReplace";
import "../Feedback/Feedback.css";
export default function Feedback(props) {
  const services = new Service();
  const [alert, setAlert] = useState(false);
  const [loading, setLoading] = useState({
    action_button: false,
    modal: false,
  });
  const containerRef = useRef(null);
  const textFieldRef = useRef(null);
  const divRef = useRef(null);
  const textInputHeightRef = useRef("40px");
  const previousNumberOfRowsRef = useRef(1);
  // const hmsActions = useHMSActions();
  const [number_of_fields, setNumber_of_fields] = useState(null);
  const [next_button_loader, setNext_button_loader] = useState(false);
  // const [feedbackLoading, setFeedbackLoading] = useState({ useEffect: false });
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    bulletIndex: null,
    fieldName: null,
  });
  const { slotsContextData } = useContext(SlotsContext);
  const { provideFeedback, setProvideFeedback } = useContext(ProvideFeedback);
  console.log("next_button_loader", next_button_loader);

  //Data Variables
  const {
    studentJoined,
    feedbackFocused,
    setFeedbackFocused,
    handleEndSessionYes,
  } = props;
  const [pageState, setPageState] = useState({
    is_edit: false,
    is_feedback_posted: false,
  });

  const location = useLocation();
  console.log("location temp feedbackL;", location.state);
  console.log("ProvideFeedbackComponent ~ pageState:", feedbackFocused);
  console.log("pageState", pageState);
  const [spokenText, setSpokenText] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const navigate = useNavigate();
  //Main data Variables
  const [mcqData, setMcqData] = useState([]);
  const [feedbackFields, setFeedbackFields] = useState([]);
  const [savedFeedbackFields, setSavedFeedbackFields] = useState({ All: 0 });
  const [savedFeedbackBullets, setSavedFeedbackBullets] = useState([]);
  const [selectedTab, setSelectedTab] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBullet, setCurrentBullet] = useState(null);
  const [currentBulletIndex, setCurrentBulletIndex] = useState(null);
  const [currentFieldName, setCurrentFieldName] = useState(null);
  // const [submittedMentorFeedback, setSubmittedMentorFeedback] = useState(false);
  // const [feedbackSubmitModal, setFeedbackSubmitModal] = useState(false);
  const [mcqModal, setMcqModal] = useState({
    open: false,
    onClose: () => setMcqModal(prev => ({ ...prev, open: false })),
    mcqs: [],
    extraData: {},
  });
  const [mentorFeedbackGiven, setMentorFeedbackGiven] = useState({
    open: false,
    count: 0,
  });
  console.log(
    "provide feedback:",
    provideFeedback,
    location?.state?.provide_feedback
  );
  const [dialogue, setDialogue] = useState({
    open: false,
    onClose: () => {
      setDialogue(prev => ({ ...prev, open: false }));
    },
    content: "",
    actions: {
      label: "OK",
      handler: () => {},
      color: Color.primary1,
      variant: "contained",
    },
  });
  const [lastDescriptionChange, setLastDescriptionChange] = useState(
    Date.now()
  );

  // const [tabDataIndex, setTabDataIndex] = useState(0);
  const [feedbackCompleteRequestModal, setFeedbackCompleteRequestModal] =
    useState(false);
  const [header, setHeader] = useState("");
  const [image, setImage] = useState("");
  const [WhyThis, setWhyThis] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [Answer, setAnswer] = useState("");
  const feedbackModal = true;
  const [feedbackPlaceholderModalOpen, setFeedbackPlaceholderModalOpen] =
    useState(false);
  const recordFeedbackPopupTime = () => {
    const currentTime = Date.now();
    sessionStorage.setItem(
      "time_json",
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem("time_json")),
        feedback_pop_up_time: currentTime,
      })
    );
  };
  console.log("mentorFeedbackGiven", mentorFeedbackGiven);
  console.log(
    "provideFeedback",
    provideFeedback,
    location?.state?.provide_feedback
  );
  // console.log("isModalOpen", isModalOpen);
  const handleOpenFeedbackModal = () => setFeedbackCompleteRequestModal(true);
  const handleOpenFeedbackPlaceholderModal = () =>
    setFeedbackPlaceholderModalOpen(true);
  const handleCloseFeedbackModal = () => {
    setFeedbackCompleteRequestModal(false);
    recordFeedbackPopupTime();
  };

  const [checked, setChecked] = React.useState(false);
  const [answerOpen, setAnswerOpen] = React.useState(false);
  const handleChangeFeedbackModal = () => {
    setChecked(prev => !prev);
  };
  const handleWhyThisClick = () => {
    setAnswerOpen(!answerOpen); // Toggle the checked state
  };

  const handleCloseFeedbackPlaceholderModal = () => {
    setFeedbackPlaceholderModalOpen(false);
  };

  useEffect(() => {
    if (
      number_of_fields > 0 &&
      (studentJoined || slotsContextData?.student_join_time !== null) &&
      savedFeedbackFields.All < number_of_fields &&
      feedbackFocused === false
    ) {
      const interval = setInterval(() => {
        checkDescriptionChanges();
      }, 3 * 60 * 1000);

      return () => clearInterval(interval);
    }
  }, [studentJoined, lastDescriptionChange, number_of_fields, feedbackFocused]);

  const handleResize = () => {
    const textarea = textFieldRef.current;
    const lineHeight = parseFloat(window.getComputedStyle(textarea).lineHeight);
    const currentRows = Math.ceil(textarea.clientHeight / lineHeight);
    console.log("currentRows", currentRows);
    // Check if the number of rows has increased from 1
    if (currentRows > 1 && currentRows > previousNumberOfRowsRef.current) {
      const scrollingAvailable = textarea.scrollHeight > textarea.clientHeight;

      if (
        containerRef.current &&
        feedbackFocused &&
        currentRows <= 4 &&
        currentRows > 1 &&
        !scrollingAvailable
      ) {
        const newTop = -textarea.scrollHeight; // Adjust based on initial height
        containerRef.current.style.marginTop = `${newTop}px`;
      }
    }

    // Update the previous number of rows
    previousNumberOfRowsRef.current = currentRows;
  };

  useEffect(() => {
    handleResize(spokenText);
  }, [spokenText]);

  const handleOpenMCQModal = event => {
    event.stopPropagation();
    const disable = handleDisableNextButton();
    if (!disable) {
      handleCloseClick(event);
      setFeedbackFocused(false);
      setMcqModal(prev => ({
        ...prev,
        open: true,
        mcqs: mcqData,
        setMcqData: setMcqData,
        extraData: {
          savedFeedbackBullets,
          setSavedFeedbackBullets,
          slotsContextData,
          setPageState,
          dialogue,
          setDialogue,
        },
      }));
    } else {
      setDialogue(prev => ({
        ...prev,
        open: true,
        actions: {
          label: "OK",
          handler: () => {
            dialogue.onClose();
          },
          color: Color.primary1,
          variant: "contained",
        },
        title: "Disabled",
        content: `There should be atleast ${number_of_fields} bullets`,
      }));
    }
  };
  console.log("savedFeedbackFields", savedFeedbackFields.All);

  const handleDisableNextButton = () => {
    let disable = false;
    if (savedFeedbackFields.All < number_of_fields) disable = true;
    return disable;
  };

  const checkDescriptionChanges = () => {
    const currentTime = Date.now();
    const threeMinutes = 3 * 60 * 1000;

    if (
      currentTime - lastDescriptionChange > threeMinutes &&
      feedbackFocused === false
    ) {
      console.log("inside feedback modal condition");
      setFeedbackCompleteRequestModal(true);
      setHeader(
        "Please keep typing feedback during the session to keep it detailed"
      );
      setImage(
        "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/OBJECTS.jpg"
      );
      setButtonText("Provide Feedback");
      setWhyThis("Why am I seeing this?");
      setAnswer(
        "For detailed actionable feedback, it is important to capture granular details of the session. Feedback (say in the case of a Mock Interview) should contain all the questions for which you did not get satisfactory answers (incomplete or wrong or no answer). This would work as a very good starting point for students. When we notice the mentor not typing feedback for a long duration during the interview, the reminder pops up. We understand that you may get engrossed in the session so a quick reminder would help us to also capture detailed feedback"
      );
    }
  };

  //     if (divRef.current) {
  //       if (mcqModal.open || feedbackFocused) {
  //         // window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to top
  //         setOverflowHidden(false); // Set overflowHidden to false when modal is open
  //       } else {
  //         const isOverflowing = divRef.current.clientHeight > 170;
  //         setOverflowHidden(isOverflowing);
  //         console.log(
  //           "Div height:",
  //           isOverflowing,
  //           divRef.current.clientHeight
  //         );
  //         if (isOverflowing) {
  //           // Scroll to top without smooth behavior if overflowing
  //           divRef.current.scrollTo({ top: 0 });
  //         }
  //       }
  //     }
  //   };

  //   checkHeight(); // Initial check

  //   window.addEventListener("resize", checkHeight);

  //   return () => {
  //     window.removeEventListener("resize", checkHeight);
  //   };
  // }, [feedbackFocused, mcqModal.open, divRef.current]); // Dependency on mcqModal.open

  //Functions
  useEffect(() => {
    handleMainPFeedbackEffect();
  }, []);

  const handleOfflineEndSessionYes = async () => {
    try {
      let body = {
        order_id: slotsContextData.id ?? slotsContextData?.order_id,
      };
      await services.put("/os/mentor/endSession", body);
      console.log("/os/mentor/endSession done");
      navigate(`/EndSession`, {
        state: {
          user_type: "mentor",
          navigate: "offline",
          process_id: location.state.process_id,
        },
      });
    } catch (err) {
      console.log("error : ", err);
      window.alert(err.response.data.message);
    }
  };

  const handleEndSessionModal = data => {
    if (data === "Yes") {
      if (window.location.pathname === "/FeedbackOverview/offline") {
        handleOfflineEndSessionYes();
      } else {
        handleEndSessionYes();
      }
      setAlert(false);
    } else setAlert(false);
  };

  const handleSubmitFeedbackModal = data => {
    if (data === "Yes") {
      handleSubmitMCQ();
    } else setMentorFeedbackGiven(prev => ({ ...prev, open: false }));
  };

  const handleDeleteBulletModal = data => {
    if (data === "Yes" && savedFeedbackFields.All >= number_of_fields)
      handleDeleteBullet(deleteModal.bulletIndex, deleteModal.fieldName);
    else
      setDeleteModal({
        isOpen: false,
        bulletIndex: null,
        fieldName: null,
      });
  };
  console.log("slotsContextData", slotsContextData);
  const handleMainPFeedbackEffect = async () => {
    try {
      setNext_button_loader(true);
      let temp_feedback_fields = await fetchFeedbackFields(slotsContextData);
      temp_feedback_fields.feedbackFields.forEach(data => {
        data.description = { bullets: [] };
        data.rating = 0;
        data.n_a = [];
      });
      if (
        window.location.pathname === "/FeedbackOverview/offline" &&
        slotsContextData?.order_flag === "Feedback"
      ) {
        setProvideFeedback(true);
      }
      setFeedbackFields(
        temp_feedback_fields.feedbackFields.sort(
          sort_by("sequence", false, parseInt)
        )
      );
      let temp_feedback_mcq = await fetchFeedbackMCQ(slotsContextData);
      temp_feedback_mcq.forEach(
        data => (
          (data.options = JSON.parse(data.options).options), (data.ans = "")
        )
      );
      setMcqData(temp_feedback_mcq.sort(sort_by("sequence", false, parseInt)));
      await fetchFeedbackFieldsAccordingToOrderId(slotsContextData);
      setNumber_of_fields(temp_feedback_fields?.no_of_fields ?? 0);
    } catch (err) {
      console.log("hello", err);
    }
  };

  const fetchFeedbackFieldsAccordingToOrderId = async slotsContextData => {
    try {
      let res =
        window.location.pathname === "/FeedbackOverview/offline"
          ? await services.get(
              `/os/mentor/osMentorFeedback?order_id=${
                slotsContextData?.id ?? slotsContextData?.order_id
              }`
            )
          : await services.get(
              `mentorModule/mentorFeedback?order_id=${slotsContextData?.id}`
            );
      let responses = {};
      if (res.data.length > 0) {
        const feedbackData = JSON.parse(res.data[0].fields_feedback); // Parse the JSON string
        responses = feedbackData.responses;
      } else {
        const feedbackData = { responses: [] };
        responses = feedbackData.responses;
      }

      const updatedFeedbackBullets = responses.reduce((acc, response) => {
        acc[response.field_name] = response.description;
        return acc;
      }, {});

      console.log(updatedFeedbackBullets);
      setSavedFeedbackBullets(updatedFeedbackBullets);

      const updatedFeedbackFields = { ...savedFeedbackFields };

      Object.keys(updatedFeedbackBullets).forEach(key => {
        updatedFeedbackFields[key] = updatedFeedbackBullets[key].bullets.length;
      });

      // Sum all counts except the "All" field
      const sumAll = Object.keys(updatedFeedbackFields)
        .filter(key => key !== "All")
        .reduce((sum, key) => sum + updatedFeedbackFields[key], 0);

      updatedFeedbackFields["All"] = sumAll;

      // Update the state
      setSavedFeedbackFields(updatedFeedbackFields);
    } catch (err) {
      console.error(err.response?.data?.message || err.message);
      window.alert(err.response.data.message);
    }
  };

  const fetchFeedbackFields = async slotsContextData => {
    try {
      const res = await services.get(
        `mentorModule/mentorFeedbackFields?act_type_id=${
          slotsContextData?.act_type_id ?? null
        }`
      );

      return { feedbackFields: res.data, no_of_fields: res.no_of_fields };
    } catch (err) {
      console.error(err.response.data.message);
      window.alert(err.response.data.message);
    }
  };

  const fetchFeedbackMCQ = async slotsContextData => {
    try {
      let res;
      res = await services.get(
        `mentorModule/mentorFeedbackMcq?act_type_id=${
          slotsContextData?.act_type_id ?? null
        }`
      );
      return res.data;
    } catch (err) {
      console.error(err.response.data.message);
      window.alert(err.response.data.message);
    }
  };

  useEffect(() => {
    if (next_button_loader) {
      console.log("here i am");

      const timer = setTimeout(() => setNext_button_loader(false), 5000);
      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [next_button_loader]);

  useEffect(() => {
    if (!spokenText) {
      setPageState(prev => ({ ...prev, is_edit: false }));
      setLastDescriptionChange(Date.now());
    } else {
      setPageState(prev => ({ ...prev, is_edit: true }));
    }
  }, [spokenText]);

  const handleChangeSpokenText = ({ target }) => {
    const { value } = target;
    setSpokenText(value);
  };

  const handleSaveFeedback = async (e, field) => {
    if (spokenText) {
      const updatedFeedbackBullets = { ...savedFeedbackBullets };
      if (!updatedFeedbackBullets[field.field_name]) {
        updatedFeedbackBullets[field.field_name] = { bullets: [] };
      }
      updatedFeedbackBullets[field.field_name].bullets.push(
        send_specialCharReplace(spokenText)
      );

      let temp_response = await handleSubmitFeedbackAndMCQ(
        updatedFeedbackBullets
      );
      if (temp_response) {
        setSavedFeedbackBullets(updatedFeedbackBullets);

        const updatedFeedbackFields = { ...savedFeedbackFields };
        updatedFeedbackFields[field.field_name] =
          (updatedFeedbackFields[field.field_name] ?? 0) + 1;

        // Sum all counts except the "All" field
        let sumAll = Object.keys(updatedFeedbackFields)
          .filter(key => key !== "All")
          .reduce((sum, key) => sum + updatedFeedbackFields[key], 0);

        updatedFeedbackFields["All"] = sumAll;
        setSavedFeedbackFields(updatedFeedbackFields);
        setPageState(prev => ({ ...prev, is_edit: false }));
        setSpokenText("");
        setFeedbackFocused(false);
        e.stopPropagation();
      }
    }
  };

  //clicking on the tab
  const handleClickTab = field => {
    console.log("handleClickTab ~ field:", field);
    setSelectedTab(field);
  };

  const handleFeedbackClick = () => {
    setFeedbackFocused(true);
  };

  const handleBackgroundClick = () => {
    setFeedbackFocused(false);
    setSelectedTab("");
    setPageState(prev => ({ ...prev, is_edit: false }));
  };

  const handleCloseClick = event => {
    event.stopPropagation();
    setFeedbackFocused(false);
    setSelectedTab("");
    setPageState(prev => ({ ...prev, is_edit: false }));
  };

  const updateFieldCounts = (oldFieldName, newFieldName) => {
    const updatedFields = { ...savedFeedbackFields };

    if (oldFieldName !== newFieldName) {
      if (oldFieldName) {
        updatedFields[oldFieldName]--;
        if (updatedFields[oldFieldName] === 0) {
          delete updatedFields[oldFieldName];
        }
      }

      if (newFieldName) {
        updatedFields[newFieldName] = (updatedFields[newFieldName] || 0) + 1;
      }
    }

    let sumAll = 0;
    Object.keys(updatedFields)
      .filter(field => field !== "All")
      .forEach(fieldValue => {
        sumAll += updatedFields[fieldValue];
      });
    updatedFields["All"] = sumAll;

    setSavedFeedbackFields(updatedFields);
  };

  const handleClickEditBullet = (event, bullet, bulletIndex, field_name) => {
    event.stopPropagation();
    setFeedbackFocused(true);
    setCurrentBullet(bullet);
    setCurrentBulletIndex(bulletIndex);
    setCurrentFieldName(field_name); // Store the field name
    setSelectedTab("");
    setPageState(prev => ({ ...prev, is_edit: false }));
    setIsModalOpen(true);
  };

  const handleDeleteModalClick = (bulletIndex, fieldName) => {
    setDeleteModal({
      isOpen: true,
      bulletIndex,
      fieldName,
    });
  };

  const handleDeleteBullet = async (bulletIndex, fieldName) => {
    const updatedBullets = { ...savedFeedbackBullets };
    updatedBullets[fieldName].bullets.splice(bulletIndex, 1);

    if (updatedBullets[fieldName].bullets.length === 0) {
      delete updatedBullets[fieldName];
      setPageState(prev => ({ ...prev, is_edit: false }));
      setSelectedTab("");
      setFeedbackFocused(false);
    }
    if (Object.keys(updatedBullets).length === 0) {
      setPageState(prev => ({ ...prev, is_edit: false }));
      setSelectedTab("");
    }

    let temp_response = await handleSubmitFeedbackAndMCQ(updatedBullets);
    if (temp_response) {
      updateFieldCounts(fieldName, null); // Pass null to indicate a bullet has been removed
      setSavedFeedbackBullets(updatedBullets);
      setDeleteModal({
        isOpen: false,
        bulletIndex: null,
        fieldName: null,
      });
    }
  };

  const handleEndSessionClick = event => {
    event.stopPropagation();
    setFeedbackFocused(false);
    setAlert(true);
  };

  const ModalComponent = ({
    bullet,
    bulletIndex,
    fieldName,
    isModalOpen,
    selectedTab,
  }) => {
    const style = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "90%",
      border: "none",
      borderRadius: "6px",
      padding: "16px 8px 16px 8px",
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      minWidth: "300px",
      zIndex: feedbackFocused ? 10 : 1,
    };

    // Render the ModalComponent
    console.log("bullet", bullet, bulletIndex);
    const [description, setDescription] = useState(bullet);
    const handleSaveBullet = async (bulletIndex, newFieldName) => {
      const updatedBullets = { ...savedFeedbackBullets };
      const oldFieldName = fieldName;
      const oldBullet = updatedBullets[oldFieldName]?.bullets[bulletIndex];

      if (oldBullet) {
        // If moving to a different field, add the edited bullet to the new field
        if (oldFieldName !== newFieldName) {
          updatedBullets[newFieldName] = updatedBullets[newFieldName] || {
            bullets: [],
          };
          updatedBullets[newFieldName].bullets.push(
            send_specialCharReplace(description)
          );

          // Remove the original bullet from the old field
          updatedBullets[oldFieldName].bullets.splice(bulletIndex, 1);

          // If the old field now has zero bullets, delete it
          if (updatedBullets[oldFieldName].bullets.length === 0) {
            delete updatedBullets[oldFieldName];
          }

          updateFieldCounts(oldFieldName, newFieldName);
        } else {
          // If staying in the same field, update the bullet in place
          updatedBullets[oldFieldName].bullets[bulletIndex] =
            send_specialCharReplace(description);
        }

        let temp_response = await handleSubmitFeedbackAndMCQ(updatedBullets);
        if (temp_response) {
          setFeedbackFocused(false);
          setSavedFeedbackBullets(updatedBullets);
          setIsModalOpen(false);
        }
      } else {
        console.error(
          `Bullet at index ${bulletIndex} does not exist in ${oldFieldName}.`
        );
      }
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
      setFeedbackFocused(false);
      setCurrentBullet(null);
      setCurrentBulletIndex(null);
      setCurrentFieldName(null); // Reset the field name
    };

    return (
      <Modal
        sx={{
          ...feedbackbackground,
          top:
            props.studentJoined || slotsContextData.student_join_time !== null
              ? "max(6%, 50px)"
              : "13.6%",
          ...(window.innerWidth > 1500 && {
            // window.location.pathname !== "/FeedbackOverview" &&
            left: "2.5%",
            width: "max(65.5%, 780px)",
          }),
          ...(window.location.pathname === "/FeedbackOverview/offline" && {
            top: "max(6%, 49px)",
          }),
        }}
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              padding: "16px 8 16px 8px",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
            ref={containerRef}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  color: Color.white,
                  textAlign: "left",
                  width: "100%",
                  fontSize: "20px",
                }}
              >
                Edit Feedback{" "}
              </Typography>
              <IconButton onClick={handleCloseModal} sx={{ padding: "0px" }}>
                <CloseIcon sx={{ color: Color.white }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                border: `1px solid ${Color.blueBlack}`,
                borderRadius: "10px",
                background: "white",
                justifyContent: "center",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <TextField
                inputRef={textFieldRef}
                multiline
                variant="standard"
                value={get_specialCharReplace(description)}
                onChange={e => setDescription(e.target.value)}
                fullWidth
                sx={{
                  width: "100%",
                  borderRadius: "8px",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  paddingLeft: "10px",
                  height: "auto",
                }}
                minRows={1}
                maxRows={4}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ alignItems: "flex-start", marginTop: "10px" }}
                    >
                      <Circle
                        sx={{
                          fontSize: "8px",
                          color: Color.neutralMidGrey,
                        }}
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: "8px",
                    height: "auto",
                    alignItems: "flex-start",
                  },
                  disableUnderline: true,
                }}
              />
            </Box>
            {description !== "" && (
              <>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: Color.accent,
                    marginBottom: "8px",
                    marginTop: "18px",
                  }}
                >
                  Select the relevant tag to save the feedback
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    mb: "16px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  {feedbackFields.map(field => (
                    <ButtonBase key={field.field_name}>
                      <Box
                        onClick={() =>
                          handleSaveBullet(bulletIndex, field.field_name)
                        }
                        sx={{
                          ...DivCenter,
                          borderRadius: "8px",
                          background:
                            field.field_name === fieldName
                              ? "transparent"
                              : Color.btnColor,
                          color:
                            field.field_name === fieldName
                              ? Color.white
                              : Color.white,
                          border: `1px solid ${Color.btnColor}`,
                          fontSize: "14px",
                          height: "32px",
                          width: "auto",
                          minWidth: "112px",
                          p: "10px",
                        }}
                      >
                        {field.field_name}
                      </Box>
                    </ButtonBase>
                  ))}
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    );
  };

  const handleSubmitMCQ = async () => {
    setLoading(prev => ({ ...prev, action_button: true }));
    let tabData_body = JSON.parse(JSON.stringify(savedFeedbackBullets));
    console.log("tabData_body ~ before:", tabData_body);

    let mcqData_body = JSON.parse(JSON.stringify(mcqData));

    let body = {};
    body = {
      order_id: slotsContextData.id ?? slotsContextData?.order_id,
      mcq_feedback: {
        responses: mcqData_body.map(data => ({
          question: data.question,
          ans: data.ans,
          id: data.id,
        })),
      },
    };

    console.log("sending data:", body);

    try {
      if (Object.entries(savedFeedbackBullets).length === 0) {
        await handleSubmitFeedbackAndMCQ(savedFeedbackBullets);
      }
      window.location.pathname === "/FeedbackOverview/offline"
        ? await services.put(`/os/mentor/osMentorMcqFeedback`, body)
        : await services.put(`/mentorModule/mentorMcqFeedback`, body);
      if (
        location?.state?.provide_feedback === true ||
        provideFeedback === true
      ) {
        navigate(-1);
      }
      setPageState(true);

      setMentorFeedbackGiven(prev => ({
        ...prev,
        count: prev.count + 1,
      }));
      setMentorFeedbackGiven(prev => ({ ...prev, open: false }));
      setSelectedTab("");
      if (window.location.pathname === "/FeedbackOverview") {
        setMcqModal(prev => ({ ...prev, open: false }));
        navigate("/");
      }
    } catch (err) {
      console.error("Error posting feedback and mcq data:", err);
      window.alert(err.response.data.message);
      setLoading(prev => ({ ...prev, action_button: false }));
    } finally {
      // Reset loading state
      setMcqModal(prev => ({ ...prev, open: false }));
      setMentorFeedbackGiven(prev => ({ ...prev, open: false }));
      setLoading(prev => ({ ...prev, action_button: false }));
    }
  };

  const handleSubmitFeedbackAndMCQ = async updatedFeedbackBullets => {
    setLoading(prev => ({ ...prev, action_button: true }));
    let tabData_body = JSON.parse(JSON.stringify(savedFeedbackBullets));
    console.log("tabData_body ~ before:", tabData_body);

    // let mcqData_body = JSON.parse(JSON.stringify(mcqData));

    let body = {};
    body = {
      order_id: slotsContextData.id ?? slotsContextData?.order_id,
      fields_feedback: {
        responses: Object.entries(updatedFeedbackBullets).map(
          ([key, value]) => ({
            field_name: key,
            description: value,
          })
        ),
      },
      // mcq_feedback: {
      //   responses: mcqData_body.map(data => ({
      //     question: data.question,
      //     ans: data.ans,
      //     id: data.id,
      //   })),
      // },
    };

    console.log("sending data:", body);

    try {
      // if (body.fields_feedback.responses.length > 0) {
      let res =
        window.location.pathname === "/FeedbackOverview/offline"
          ? await services.post(`/os/mentor/osMentorFieldsFeedback`, body)
          : await services.post(`/mentorModule/mentorFieldsFeedback`, body);
      setPageState(true);
      setLoading(prev => ({ ...prev, action_button: false }));
      if (res.status === 200) {
        return true;
      } else {
        window.alert(
          "Your feedback point has not been saved. Please resubmit."
        );
        return false;
      }
      // } else {
      //   window.alert("Can't save empty feedback");
      // }
    } catch (err) {
      console.error("Error posting feedback and mcq data:", err);
      window.alert(err.response.data.message);
      setLoading(prev => ({ ...prev, action_button: false }));
    }

    // setMcqModal(prev => ({ ...prev, open: false }));
  };

  const MCQModal = ({ open, onClose, mcqData, setMcqData }) => {
    const [localMcqData, setLocalMcqData] = useState([]);
    const [mcqLoader, setMcqLoader] = useState(false);
    const stackOptions = [
      {
        option: "Poor",
        color:
          window.location.pathname === "/FeedbackOverview/offline"
            ? "#D32F2F"
            : "#FF4C4C",
      },
      {
        option: "Fair",
        color:
          window.location.pathname === "/FeedbackOverview/offline"
            ? "#F57C00"
            : "#FF944C",
      },
      {
        option: "Average",
        color:
          window.location.pathname === "/FeedbackOverview/offline"
            ? "#FBC02D"
            : "#FFC94C",
      },
      {
        option: "Good",
        color:
          window.location.pathname === "/FeedbackOverview/offline"
            ? "#8BC34A"
            : "#D4FF4C  ",
      },
      {
        option: "Excellent",
        color:
          window.location.pathname === "/FeedbackOverview/offline"
            ? "#388E3C"
            : "#4CFF4C",
      },
    ];
    useEffect(() => {
      if (open) {
        setMcqLoader(true);
        setLocalMcqData([...mcqData]);
        setMcqLoader(false);
      }
    }, [open, mcqData]);

    const handleChange = useCallback((e, mcq_index) => {
      const { value } = e.target;
      setLocalMcqData(prevLocalMcqData => {
        const updatedLocalMcqData = [...prevLocalMcqData];
        updatedLocalMcqData[mcq_index].ans = value;
        console.log("mcq ndvf", updatedLocalMcqData);
        return updatedLocalMcqData;
      });
    }, []);

    const MCQItem = memo(({ mcq, mcq_index }) => {
      const [tooltipOpen, setTooltipOpen] = useState(null);

      const handleRadioChange = (e, mcq_index) => {
        const { value } = e.target;
        const isMentor = true;

        if (isMentor) {
          if (value === "Yes") {
            setTooltipOpen("Yes");
            setTimeout(() => {
              setTooltipOpen(null);
            }, 2000); // Close tooltip after 2 seconds
          } else if (value === "4" || value === "5") {
            setTooltipOpen(value);
            setTimeout(() => {
              setTooltipOpen(null);
            }, 2000); // Close tooltip after 2 seconds
          } else {
            setTooltipOpen(null);
          }
        }

        handleChange(e, mcq_index);
      };

      return (
        <Box
          key={mcq_index}
          sx={{
            ...DivCenter,
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "100%",
            marginBottom: "16px",
          }}
        >
          <Typography
            style={{
              textAlign: "left",
              width: "100%",
              fontSize: "16px",
              color:
                window.location.pathname === "/FeedbackOverview/offline"
                  ? Color.blueBlack
                  : Color.white,
            }}
          >
            {mcq.sequence}. {mcq.question}
          </Typography>
          <Box
            sx={{
              ...DivCenter,
              width: "100%",
              justifyContent: "left",
            }}
          >
            <RadioGroup
              row
              aria-labelledby={`controlled-radio-buttons-group-${mcq_index}`}
              name={`controlled-radio-buttons-group-${mcq_index}`}
              value={mcq.ans}
              onChange={e => handleRadioChange(e, mcq_index)}
              sx={{
                ...DivCenter,
                width: "100%",
                justifyContent: "left",
                color:
                  window.location.pathname === "/FeedbackOverview/offline"
                    ? Color.blueBlack
                    : Color.white,
              }}
            >
              {mcq.options.map((option, option_index) => (
                <Tooltip
                  key={option_index}
                  title={
                    option === "Yes" && tooltipOpen === "Yes"
                      ? "Are you really sure? A ‘Yes’ may turn them laid back."
                      : (option === "4" || option === "5") &&
                        tooltipOpen === option
                      ? "Be Conservative, 4 or 5 should be given for extraordinary performance."
                      : ""
                  }
                  placement="right"
                  arrow
                  open={tooltipOpen === option && mcq.ans !== option}
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [-5, 64], // Adjust the offset to add space (64px = 4rem)
                        },
                      },
                    ],
                  }}
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor:
                          window.location.pathname ===
                          "/FeedbackOverview/offline"
                            ? "rgba(26, 128, 150, 0.10)"
                            : "rgba(26, 128, 150, 0.5)",
                        color:
                          window.location.pathname ===
                          "/FeedbackOverview/offline"
                            ? "#325C65"
                            : "rgba(103, 173, 188, 0.8)",
                        fontSize: "12px",
                        borderRadius: "4px",
                        padding: "4px",
                        maxWidth: "none",
                        minWidth: "auto",
                        width: "auto",
                        textAlign: "left",
                      },
                    },
                    arrow: {
                      sx: {
                        display: "none",
                        color: "rgba(26, 128, 150, 0.5)",
                      },
                    },
                  }}
                >
                  <FormControlLabel
                    sx={{ margin: "0px" }}
                    value={option}
                    control={
                      <Radio
                        sx={{
                          color: Color.neutralMidGrey,
                          "&.Mui-checked": {
                            color:
                              option === "Yes" || option === "5"
                                ? "#4CFF4C"
                                : option === "No" || option === "1"
                                ? "#FF4C4C"
                                : option === "2"
                                ? "#FF944C"
                                : option === "3"
                                ? "#FFC94C"
                                : option === "4"
                                ? "#D4FF4C"
                                : Color.accent,
                            ...(window.location.pathname ===
                              "/FeedbackOverview/offline" && {
                              color:
                                option === "Yes" || option === "5"
                                  ? "#388E3C"
                                  : option === "No" || option === "1"
                                  ? "#D32F2F"
                                  : option === "2"
                                  ? "#F57C00"
                                  : option === "3"
                                  ? "#FBC02D"
                                  : option === "4"
                                  ? "#8BC34A"
                                  : Color.accent,
                            }),
                          },
                          "&:hover": {
                            color:
                              option === "Yes" || option === "5"
                                ? "#4CFF4C"
                                : option === "No" || option === "1"
                                ? "#FF4C4C"
                                : option === "2"
                                ? "#FF944C"
                                : option === "3"
                                ? "#FFC94C"
                                : option === "4"
                                ? "#D4FF4C"
                                : Color.accent,
                            backgroundColor: "transparent",
                            ...(window.location.pathname ===
                              "/FeedbackOverview/offline" && {
                              color:
                                option === "Yes" || option === "5"
                                  ? "#388E3C"
                                  : option === "No" || option === "1"
                                  ? "#D32F2F"
                                  : option === "2"
                                  ? "#F57C00"
                                  : option === "3"
                                  ? "#FBC02D"
                                  : option === "4"
                                  ? "#8BC34A"
                                  : Color.accent,
                            }),
                          },
                        }}
                      />
                    }
                    label={option}
                    labelPlacement="bottom"
                    onMouseEnter={() => {
                      if (mcq.ans !== option) {
                        setTooltipOpen(option);
                      }
                    }}
                    onMouseLeave={() => {
                      setTooltipOpen(null);
                    }}
                  />
                </Tooltip>
              ))}
            </RadioGroup>
          </Box>
        </Box>
      );
    });

    const onCloseMcq = () => {
      onClose();
      setSelectedTab("");
    };

    const handleSubmit = () => {
      setMcqData(localMcqData);
      setMentorFeedbackGiven(prev => ({ ...prev, open: true }));
    };

    const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: "transparent",
      ...theme.typography.body2,
      color:
        window.location.pathname === "/FeedbackOverview/offline"
          ? Color.blueBlack
          : Color.white,
      textAlign: "center",
      boxShadow: "none",
      margin: "auto 0",
    }));

    const FilledBox = ({ color }) => (
      <Box
        sx={{
          width: 20,
          height: 20,
          backgroundColor: color,
          borderRadius: "3px",
        }}
      />
    );

    return (
      <Modal open={open} onClose={onCloseMcq}>
        <Box
          sx={{
            ...feedbackbackground,
            maxHeight: "90%",
            position: "absolute",
            zIndex: 1,
            height: "auto",
            top:
              studentJoined || slotsContextData.student_join_time !== null
                ? "max(6%, 50px)"
                : "13.6%",
            ...DivCenter,
            justifyContent: "flex-start",
            ...(window.innerWidth > 1500 && {
              left: "2.5% !important",
              width: "max(65.5%, 780px)",
            }),
            ...(window.innerWidth > 1700 && {
              left: "7.4% !important",
              width: "max(60.5%, 780px)",
            }),
            padding: "24px",
            background: "rgba(0, 0, 0, 0.55)",
            backdropFilter: "blur(8px)",
            overflow: "auto",
            ...(window.location.pathname === "/FeedbackOverview/offline" && {
              top: "max(6%, 49px)",
              background: "rgba(164, 164, 164, 0.75)",
            }),
          }}
        >
          <Box
            sx={{
              backgroundColor: mcqModal.open
                ? "rgba(0, 0, 0, 0.1)"
                : "transparent",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "left",
              alignItems: "flex-start",
              padding: "16px 8px 16px 8px",
              borderBottomLeftRadius: "6px",
              borderBottomRightRadius: "6px",
              maxHeight: "calc(100% - 60px)",
              ...(window.location.pathname === "/FeedbackOverview/offline" && {
                backgroundColor: "#E6E6E6",
              }),
            }}
          >
            <Box
              sx={{
                ...DivCenter,
                justifyContent: "space-between",
                width: "100%",
                paddingBottom: "16px",
              }}
            >
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: "medium",
                  color:
                    window.location.pathname === "/FeedbackOverview/offline"
                      ? Color.blueBlack
                      : "rgba(255, 255, 255, 1)",
                }}
              >
                Evaluate Student Progress
              </Typography>
              <Box>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={{}}>
                  {stackOptions.map(item => {
                    return (
                      <Box
                        sx={{
                          margin: "auto 0",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <Checkbox
                          checked
                          sx={{
                            color: item.color,
                            "&.Mui-checked": {
                              color: item.color,
                            },
                            transform: "scale(0.9)",
                          }}
                          icon={<FilledBox color={item.color} />}
                          checkedIcon={<FilledBox color={item.color} />}
                        />
                        <Item>{item.option}</Item>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
              <IconButton onClick={onCloseMcq}>
                <Close
                  sx={{
                    color:
                      window.location.pathname === "/FeedbackOverview/offline"
                        ? Color.blueBlack
                        : Color.white,
                  }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                ...DivCenter,
                width: "100%",
                overflowY: "auto",
                flexDirection: "column",
              }}
            >
              {mcqLoader ? (
                <CircularProgress
                  sx={{
                    color:
                      window.location.pathname === "/FeedbackOverview/offline"
                        ? Color.blueBlack
                        : Color.white,
                  }}
                />
              ) : (
                localMcqData?.map((mcq, mcq_index) => (
                  <MCQItem key={mcq_index} mcq={mcq} mcq_index={mcq_index} />
                ))
              )}
            </Box>
            <Box
              sx={{
                ...DivCenter,
                width: "200px",
                height: "10%",
                gap: "16px",
                margin: "auto",
              }}
            >
              <Button
                variant="contained"
                disabled={localMcqData.every(mcq => !mcq.ans)}
                onClick={handleSubmit}
                sx={{
                  ...styles.button_styles.contained,
                  fontSize: "20px",
                  width: "200px",
                  height: "40px",
                  textTransform: "capitalize",
                  "&:disabled": {
                    background:
                      window.location.pathname === "/FeedbackOverview/offline"
                        ? "#DEDEDE"
                        : Color.neutralLightGrey,
                    color: Color.neutralMidGrey,
                    boxShadow: "none",
                  },
                  margin: "auto",
                }}
              >
                {loading.action_button ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color:
                        window.location.pathname === "/FeedbackOverview/offline"
                          ? Color.blueBlack
                          : Color.white,
                    }}
                  />
                ) : (
                  "SUBMIT"
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  console.log("spokenText", spokenText);

  return (
    <Box sx={{ width: "100%", marginTop: ".5rem" }}>
      {!mcqModal.open && (
        <Box
          sx={{
            ...(window.location.pathname !== "/FeedbackOverview" && {
              width: "100%",
            }),
            zIndex: feedbackFocused ? 10 : 1,
            backgroundColor: feedbackFocused
              ? "rgba(0, 0, 0, 0.1)"
              : "transparent",
            ...(window.location.pathname === "/FeedbackOverview/offline" && {
              backgroundColor: feedbackFocused
                ? "rgba(164, 164, 164, 0.75)"
                : "transparent",
            }),
            top: `${feedbackFocused && selectedTab ? "10%" : "25%"}`,
            borderRadius: "6px",
            position: "relative",
          }}
          onClick={handleFeedbackClick}
          className={
            window.location.pathname === "/FeedbackOverview"
              ? `feedback-wrapper ${feedbackFocused ? "focused" : "unfocused"}`
              : `feedback-wrapper-black ${
                  feedbackFocused ? "focused" : "unfocused"
                }`
          }
        >
          <Box
            sx={{
              position: "relative",
              ...((!feedbackFocused ||
                selectedTab ||
                (!pageState.is_edit && spokenText === "")) && {
                marginTop: "0px !important",
              }),
            }}
            ref={containerRef}
          >
            <Box
              sx={{
                ...DivCenter,
                flexDirection: "column",
                width: "100%",
                paddingLeft: "0px",
                position: "absolute",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: studentJoined ? "10px" : "8px",
                backgroundColor: feedbackFocused
                  ? "rgba(0, 0, 0, 0.1)"
                  : "transparent",
                borderRadius: "6px",
                ...(window.location.pathname ===
                  "/FeedbackOverview/offline" && {
                  backgroundColor: feedbackFocused ? "#E6E6E6" : "transparent",
                }),
              }}
              ref={divRef}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: feedbackFocused ? "8px" : "0px",
                  paddingTop: feedbackFocused ? "16px" : "0px",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    style={{
                      color:
                        [
                          "/FeedbackOverview",
                          "/FeedbackOverview/offline",
                        ].includes(window.location.pathname) && !feedbackFocused
                          ? Color.blueBlack
                          : Color.white,
                      textAlign: "left",
                      width: "100%",
                      fontSize: "20px",
                      ...(window.location.pathname ===
                        "/FeedbackOverview/offline" &&
                        feedbackFocused && {
                          color: Color.blueBlack,
                        }),
                    }}
                  >
                    {feedbackFocused && !selectedTab
                      ? `Provide Feedback`
                      : (!feedbackFocused && savedFeedbackFields.All > 0) ||
                        (feedbackFocused && selectedTab)
                      ? "Your Feedback"
                      : "Provide Feedback"}
                    {!feedbackFocused &&
                      savedFeedbackFields.All < 15 &&
                      number_of_fields > 0 && (
                        <span
                          style={{
                            color:
                              window.location.pathname ===
                              "/FeedbackOverview/offline"
                                ? Color.offlineAccentColor
                                : Color.accent,
                            fontSize: "16px",
                            marginLeft: ".3rem",
                          }}
                        >
                          {`(Provide at least ${number_of_fields} specific areas for the student's
                      enhancement)`}
                        </span>
                      )}
                  </Typography>
                  {feedbackFocused && (
                    <IconButton
                      onClick={handleCloseClick}
                      sx={{ padding: "0px" }}
                    >
                      <Close
                        sx={{
                          color:
                            window.location.pathname ===
                            "/FeedbackOverview/offline"
                              ? Color.blueBlack
                              : "white",
                        }}
                      />
                    </IconButton>
                  )}
                </Box>
                <Box
                  sx={{
                    ...DivCenter,
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "8px",
                    flexWrap: "wrap",
                  }}
                >
                  {savedFeedbackFields.All > 0 &&
                    (!feedbackFocused || selectedTab) && (
                      <>
                        {Object.keys(savedFeedbackFields).map(field => (
                          <Typography
                            onClick={() => {
                              handleClickTab(field);
                            }}
                            sx={{
                              fontSize: "16px",
                              color: Color.primary1,
                              textDecoration:
                                selectedTab === field ? "underline" : "none",
                              textUnderlinePosition: "under",
                              textUnderlineOffset: "3px",
                              cursor: "pointer",
                              "&:hover": { textDecoration: "underline" },
                            }}
                            key={field}
                          >
                            {field}
                            {savedFeedbackFields[field] && (
                              <>
                                (
                                <span style={{ textDecoration: "none" }}>
                                  {savedFeedbackFields[field]}
                                </span>
                                )
                              </>
                            )}
                          </Typography>
                        ))}
                      </>
                    )}
                  {(slotsContextData.mentor_feedback_submitted === 1 ||
                    mentorFeedbackGiven.count > 0) &&
                    // savedFeedbackFields.All > 0 &&
                    (!feedbackFocused || selectedTab) && (
                      <button
                        onClick={e => handleOpenMCQModal(e)}
                        style={{
                          ...DivCenter,
                          borderRadius: "12px",
                          border: `1px solid ${Color.primary1}`,
                          color:
                            window.location.pathname ===
                              "/FeedbackOverview/offline" ||
                            window.location.pathname === "/FeedbackOverview"
                              ? Color.primary1
                              : Color.white,
                          fontSize: "14px",
                          height: "32px",
                          width: "auto",
                          minWidth: "112px",
                          paddingLeft: ".5rem",
                          paddingRight: ".5rem",
                          flexWrap: "wrap",
                          textAlign: "center",
                          cursor: "pointer",
                          outline: "none",
                          "&:hover": {
                            background: Color.btnColor,
                          },
                        }}
                      >
                        Review Ratings
                      </button>
                    )}
                </Box>
              </Box>
              {selectedTab ? (
                <>
                  <Box
                    sx={{
                      ...DivCenter,
                      width: "97%",
                      height: "auto",
                      gap: "16px",
                      margin: "0px auto 1rem auto",
                      top: "8%",
                    }}
                  >
                    <Box
                      sx={{
                        ...DivCenter,
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        background: "rgba(244, 244, 244, 1)",
                        width: "100%",
                        height: selectedTab === "All" ? "560px" : "auto",
                        minHeight: "40px",
                        borderRadius: "12px",
                        p: "20px 16px 20px 16px",
                        gap: "4px",
                        bottom: "0px",
                        overflowY: selectedTab === "All" ? "auto" : "visible",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                          gap: "16px",
                          height: "auto",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: ".5rem",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "14px",
                              cursor: "default",
                              color: Color.accent,
                              margin: "auto 0",
                            }}
                          >
                            (Hover on a specific feedback to edit)
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          ...DivCenter,
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "left",
                          padding: "8px",
                          borderRadius: "10px",
                          boxShadow:
                            selectedTab !== "All"
                              ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                              : "none",
                        }}
                      >
                        {selectedTab !== "All" ? (
                          <>
                            <Box
                              sx={{
                                textAlign: "left",
                                width: "100%",
                                fontWeight: "medium",
                                fontSize: "16px",
                              }}
                            >
                              {selectedTab}
                            </Box>
                            {savedFeedbackBullets[selectedTab]?.bullets.map(
                              (bullet, bullet_index) => (
                                <List
                                  component="ul"
                                  sx={{
                                    ...DivCenter,
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    width: "100%",
                                    pt: "0",
                                    pb: "0",
                                    position: "relative",
                                  }}
                                  key={bullet_index}
                                >
                                  <ListItem
                                    onMouseEnter={() =>
                                      setHoveredIndex(bullet_index)
                                    }
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    sx={{
                                      pt: "0",
                                      pb: "0",
                                      backgroundColor:
                                        hoveredIndex === bullet_index
                                          ? "#EFEFEF"
                                          : "inherit",
                                      display: "flex",
                                      alignItems: "flex-start",
                                      cursor: "pointer",
                                      transition: "background-color 0.3s",
                                      position: "relative",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <ListItemIcon
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-start",
                                        minWidth: "16px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <Circle
                                        sx={{
                                          fontSize: "8px",
                                          color: Color.neutralMidGrey,
                                          display: "flex",
                                          alignItems: "flex-start",
                                        }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{
                                        fontSize: "14px",
                                        display: "flex",
                                        alignItems: "flex-start",
                                      }}
                                      primary={get_specialCharReplace(bullet)}
                                    />
                                    {hoveredIndex === bullet_index && (
                                      <Box
                                        className="textFade"
                                        sx={{
                                          position: "absolute",
                                          right: 0,
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          width: "150px",
                                          height: "100%",
                                          backgroundColor: "#EFEFEF",
                                          backgroundImage:
                                            "linear-gradient(90deg, transparent 150px, rgba(255, 255, 255, 0.3) 10%, rgba(232, 232, 232, 1) 8%, rgba(237, 237, 237, 1) 74%)",
                                          zIndex: 999,
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <button
                                            onClick={e =>
                                              handleClickEditBullet(
                                                e,
                                                bullet,
                                                bullet_index,
                                                selectedTab
                                              )
                                            }
                                            style={{
                                              ...DivCenter,
                                              borderRadius: "8px",
                                              background: "transparent",
                                              color: Color.primary1,
                                              border: `1px solid ${Color.primary1}`,
                                              fontSize: "14px",
                                              height: "30px",
                                              width: "auto",
                                              minWidth: "70px",
                                              textTransform: "none",
                                              boxShadow: "none",
                                              p: "8px",
                                              m: "2px",
                                              marginRight: "5px",
                                            }}
                                            onMouseEnter={e =>
                                              (e.target.style.boxShadow =
                                                "none")
                                            }
                                            onMouseLeave={e =>
                                              (e.target.style.boxShadow = "")
                                            }
                                          >
                                            Edit
                                          </button>
                                          <VerticalDivider
                                            css={{
                                              mx: "$2",
                                              color: Color.neutralMidGrey,
                                            }}
                                          />
                                          <DeleteOutlineOutlinedIcon
                                            onClick={() =>
                                              handleDeleteModalClick(
                                                bullet_index,
                                                selectedTab
                                              )
                                            }
                                            sx={{
                                              color: Color.meetRed,
                                              padding: "0px",
                                              cursor: "pointer",
                                              margin: "auto 0",
                                            }}
                                          />
                                        </Box>
                                      </Box>
                                    )}
                                  </ListItem>
                                </List>
                              )
                            )}
                          </>
                        ) : (
                          Object.entries(savedFeedbackBullets).map(
                            ([field_name, fieldData]) => (
                              <Box
                                key={field_name}
                                sx={{
                                  width: "100%",
                                  boxShadow:
                                    "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  padding: "8px",
                                  borderRadius: "10px",
                                  mb: 2,
                                }}
                              >
                                <Typography>{field_name}</Typography>
                                {fieldData.bullets.map(
                                  (bullet, bullet_index) => (
                                    <List
                                      component="ul"
                                      sx={{
                                        ...DivCenter,
                                        justifyContent: "flex-start",
                                        alignItems: "flex-start",
                                        width: "100%",
                                        pt: "0",
                                        pb: "0",
                                        position: "relative",
                                      }}
                                      key={`${field_name}-${bullet_index}`}
                                    >
                                      <ListItem
                                        onMouseEnter={() =>
                                          setHoveredIndex(
                                            `${field_name}-${bullet_index}`
                                          )
                                        }
                                        onMouseLeave={() =>
                                          setHoveredIndex(null)
                                        }
                                        sx={{
                                          pt: "0",
                                          pb: "0",
                                          backgroundColor:
                                            hoveredIndex ===
                                            `${field_name}-${bullet_index}`
                                              ? "#EFEFEF"
                                              : "inherit",
                                          display: "flex",
                                          alignItems: "flex-start",
                                          cursor: "pointer",
                                          transition: "background-color 0.3s",
                                          position: "relative",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <ListItemIcon
                                          sx={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            minWidth: "16px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          <Circle
                                            sx={{
                                              fontSize: "8px",
                                              color: Color.neutralMidGrey,
                                              display: "flex",
                                              alignItems: "flex-start",
                                            }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText
                                          sx={{
                                            fontSize: "14px",
                                            display: "flex",
                                            alignItems: "flex-start",
                                          }}
                                          primary={get_specialCharReplace(
                                            bullet
                                          )}
                                        />
                                        {hoveredIndex ===
                                          `${field_name}-${bullet_index}` && (
                                          <Box
                                            sx={{
                                              position: "absolute",
                                              right: 0,
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              width: "150px",
                                              height: "100%",
                                              backgroundColor: "#EFEFEF",
                                              backgroundImage:
                                                "linear-gradient(90deg, transparent 150px, rgba(255, 255, 255, 0.3) 10%, rgba(232, 232, 232, 1) 8%, rgba(237, 237, 237, 1) 74%)",
                                              zIndex: 999,
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                flex: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <button
                                                onClick={e =>
                                                  handleClickEditBullet(
                                                    e,
                                                    bullet,
                                                    bullet_index,
                                                    field_name
                                                  )
                                                }
                                                style={{
                                                  ...DivCenter,
                                                  borderRadius: "8px",
                                                  background: "transparent",
                                                  color: Color.primary1,
                                                  border: `1px solid ${Color.primary1}`,
                                                  fontSize: "14px",
                                                  height: "30px",
                                                  width: "auto",
                                                  minWidth: "70px",
                                                  textTransform: "none",
                                                  boxShadow: "none",
                                                  p: "10px",
                                                  m: "2px",
                                                  marginRight: "5px",
                                                }}
                                                onMouseEnter={e =>
                                                  (e.target.style.boxShadow =
                                                    "none")
                                                }
                                                onMouseLeave={e =>
                                                  (e.target.style.boxShadow =
                                                    "")
                                                }
                                              >
                                                Edit
                                              </button>
                                              <VerticalDivider
                                                css={{
                                                  mx: "$2",
                                                  color: Color.neutralMidGrey,
                                                }}
                                              />
                                              <DeleteOutlineOutlinedIcon
                                                onClick={() =>
                                                  handleDeleteModalClick(
                                                    bullet_index,
                                                    field_name
                                                  )
                                                }
                                                sx={{
                                                  color: Color.meetRed,
                                                  padding: "0px",
                                                  cursor: "pointer",
                                                  margin: "auto 0",
                                                }}
                                              />
                                            </Box>
                                          </Box>
                                        )}
                                      </ListItem>
                                    </List>
                                  )
                                )}
                              </Box>
                            )
                          )
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      ...DivCenter,
                      width: "100%",
                      height: "auto",
                      gap: "16px",
                      ...(feedbackFocused && { paddingBottom: "1rem" }),
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    {/* WHITE - TEXTBOX */}

                    <Box
                      sx={{
                        ...DivCenter,
                        justifyContent: "flex-start",
                        width: feedbackFocused ? "97%" : "100%",
                        height: "auto",
                        border:
                          [
                            "/FeedbackOverview",
                            "/FeedbackOverview/offline",
                          ].includes(window.location.pathname) &&
                          !feedbackFocused
                            ? `1px solid ${Color.blueBlack}`
                            : window.location.pathname ===
                                "/FeedbackOverview" && feedbackFocused
                            ? "none"
                            : `1px solid ${Color.white}`,
                        minHeight: "40px",
                        borderRadius: "12px",
                        p: "5px",
                        background: feedbackFocused
                          ? Color.white
                          : "transparent",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: "97%",
                          gap: "16px",
                          height: "auto",
                          justifyContent: "center",
                        }}
                      >
                        <TextField
                          color={
                            window.location.pathname ===
                            "/FeedbackOverview/offline"
                              ? Color.blueBlack
                              : [
                                  "streaming",
                                  "preview",
                                  "meeting",
                                  "leave",
                                ].includes(
                                  window.location.pathname.split("/")[1]
                                )
                              ? Color.neutralLightGrey
                              : Color.blueBlack
                          }
                          multiline
                          placeholder="Click to type your feedback"
                          variant="standard"
                          value={feedbackFocused ? spokenText : ""}
                          fullWidth
                          onChange={e => handleChangeSpokenText(e)}
                          sx={{
                            color: [
                              "/FeedbackOverview",
                              "/FeedbackOverview/offline",
                            ].includes(window.location.pathname)
                              ? Color.blueBlack
                              : Color.neutralLightGrey,
                            border: "none",
                            width: feedbackFocused ? "97%" : "100%",
                            padding: 0,
                            alignItems: "flex-start",
                            justifyContent: "center",
                            height: textInputHeightRef,
                            "& .MuiInputBase-input::placeholder": {
                              color:
                                window.location.pathname ===
                                  "/FeedbackOverview" ||
                                window.location.pathname ===
                                  "/FeedbackOverview/offline"
                                  ? Color.blueBlack
                                  : Color.neutralLightGrey,
                              opacity: 1,
                            },
                          }}
                          inputRef={textFieldRef}
                          // onResize={handleResize}
                          minRows={1}
                          maxRows={4}
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              color: Color.blueBlack,
                              height: "auto",
                              alignItems: "flex-start",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {feedbackFocused &&
                    (pageState.is_edit || spokenText !== "" ? (
                      <Box
                        sx={{
                          width: "100%",
                          // backgroundColor: feedbackFocused
                          //   ? "rgba(0, 0, 0, 0.1)"
                          //   : "transparent",
                          padding: "0px 8px 8px 8px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: Color.accent,
                            marginBottom: "8px",
                          }}
                        >
                          Select the relevant tag to save the feedback
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                            // mb: "16px",
                            width: "100%",
                            flexWrap: "wrap",
                          }}
                        >
                          {feedbackFields.map(field => (
                            <button
                              key={field.field_name}
                              onClick={e => handleSaveFeedback(e, field)}
                              style={{
                                ...DivCenter,
                                borderRadius: "8px",
                                background: Color.primary1,
                                color: Color.white,
                                fontSize: "14px",
                                height: "32px",
                                width: "auto",
                                minWidth: "112px",
                                paddingLeft: ".5rem",
                                paddingRight: ".5rem",
                                flexWrap: "wrap",
                                textAlign: "center",
                                cursor: "pointer",
                                outline: "none",
                                "&:hover": {
                                  background: Color.btnColor,
                                },
                              }}
                            >
                              {field.field_name}
                            </button>
                          ))}
                        </Box>
                      </Box>
                    ) : null)}
                  {!feedbackFocused ? (
                    // mcqData.every(mcq => mcq.ans) &&
                    (mentorFeedbackGiven.count > 0 ||
                      slotsContextData.mentor_feedback_submitted === 1) &&
                    window.location.pathname !== "/FeedbackOverview" &&
                    (!provideFeedback || !location?.state?.provide_feedback) ? (
                      <Box sx={{ ...DivCenter, width: "100%" }}>
                        <Button
                          variant="contained"
                          // disabled={submittedMentorFeedback}
                          onClick={e => handleEndSessionClick(e)}
                          sx={{
                            ...styles.button_styles.contained,
                            fontSize: "20px",
                            width: "25%",
                            background: Color.meetRed,
                            height: "40px",
                            textTransform: "capitalize",
                            "&:disabled": {
                              background: Color.meetRed,
                              color: Color.white,
                              boxShadow: "none",
                            },
                            "&:hover": {
                              background:
                                window.location.pathname ===
                                "/FeedbackOverview/offline"
                                  ? Color.offlineRedColor
                                  : Color.meetRed,
                              color: Color.white,
                              boxShadow: "none",
                            },
                            ...(window.location.pathname ===
                              "/FeedbackOverview/offline" && {
                              marginTop: "8px",
                              marginBottom: "1rem",
                            }),
                          }}
                        >
                          End Session
                        </Button>
                      </Box>
                    ) : (
                      <Box sx={{ ...DivCenter, width: "100%" }}>
                        <Button
                          variant="contained"
                          disabled={
                            number_of_fields === 0
                              ? false
                              : savedFeedbackFields.All === 0
                              ? true
                              : savedFeedbackFields.All < number_of_fields
                          }
                          onClick={e => {
                            setPageState(prev => ({ ...prev, is_edit: false }));
                            handleOpenMCQModal(e);
                          }}
                          sx={{
                            ...styles.button_styles.contained,
                            fontSize: "20px",
                            width: "25%",
                            height: "40px",
                            textTransform: "capitalize",
                            "&:disabled": {
                              background: Color.neutralLightGrey,
                              color: Color.neutralMidGrey,
                              boxShadow: "none",
                            },
                            ...(window.location.pathname ===
                              "/FeedbackOverview/offline" && {
                              marginTop: "8px",
                              marginBottom: "1rem",
                            }),
                          }}
                        >
                          {!next_button_loader ? (
                            "NEXT"
                          ) : (
                            <CircularProgress
                              size={24}
                              sx={{
                                color:
                                  window.location.pathname ===
                                  "/FeedbackOverview/offline"
                                    ? Color.blueBlack
                                    : Color.white,
                              }}
                            />
                          )}
                        </Button>
                      </Box>
                    )
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}

      {mcqModal.open && (
        <MCQModal
          open={mcqModal.open}
          onClose={mcqModal.onClose}
          mcqData={mcqData}
          setMcqData={setMcqData}
        />
      )}
      {isModalOpen && (
        <ModalComponent
          bullet={currentBullet}
          bulletIndex={currentBulletIndex}
          fieldName={currentFieldName} // Pass the field name
          feedbackFields={feedbackFields}
          isModalOpen={isModalOpen}
          selectedTab={selectedTab}
        />
      )}
      {dialogue.open && (
        <DialogueBox
          open={dialogue.open}
          onClose={dialogue.onClose}
          title={dialogue.title}
          actions={dialogue.actions}
          content={
            <>
              <p>{dialogue.content}</p>
            </>
          }
        />
      )}
      {number_of_fields > 0 &&
        window.location.pathname !== "/FeedbackOverview" &&
        window.location.pathname !== "/FeedbackOverview/offline" && (
          <FeedbackModal
            studentJoined={studentJoined}
            checked={checked}
            header={header}
            answerOpen={answerOpen}
            setAnswerOpen={setAnswerOpen}
            handleWhyThisClick={handleWhyThisClick}
            setChecked={setChecked}
            open={feedbackCompleteRequestModal}
            setOpen={setFeedbackCompleteRequestModal}
            image={image}
            WhyThis={WhyThis}
            buttonText={buttonText}
            Answer={Answer}
            handleChange={handleChangeFeedbackModal}
            handleOpen={handleOpenFeedbackModal}
            handleClose={handleCloseFeedbackModal}
            feedbackModal={feedbackModal}
          />
        )}
      <PlaceholderModal
        open={feedbackPlaceholderModalOpen}
        handleClose={handleCloseFeedbackPlaceholderModal}
        handleOpen={handleOpenFeedbackPlaceholderModal}
        setOpen={setFeedbackPlaceholderModalOpen}
      />
      <Alert
        alert={alert}
        setAlert={setAlert}
        body="Are you sure you want to end this session?"
        responseAlert={handleEndSessionModal}
      />
      <Alert
        alert={deleteModal.isOpen}
        setAlert={isOpen =>
          setDeleteModal(prevState => ({ ...prevState, isOpen }))
        }
        origin="delete"
        container={
          savedFeedbackFields.All <= number_of_fields
            ? "Got it"
            : "handleDelete"
        }
        image={
          savedFeedbackFields.All <= number_of_fields
            ? "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Frame+2611108.png"
            : "https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Frame+2611108t.png"
        }
        heading="Confirmation"
        body={
          savedFeedbackFields.All <= number_of_fields ? (
            <p>
              To delete this feedback, there must be more than{" "}
              {number_of_fields} feedback points. <br />
              Please add more points or edit the selected feedback instead.
            </p>
          ) : (
            <p>
              This is an irreversible step. We can't retrieve the data once
              deleted. <br />
              Do you still want to delete it?
            </p>
          )
        }
        responseAlert={handleDeleteBulletModal}
      />
      <Alert
        alert={mentorFeedbackGiven.open}
        setAlert={() =>
          setMentorFeedbackGiven(prev => ({ ...prev, open: false }))
        }
        noButton="Cancel"
        yesButton="Submit"
        body="Do you wish to submit your feedback?"
        responseAlert={handleSubmitFeedbackModal}
      />

      {feedbackFocused && (
        <Box
          className="feedback-overlay"
          sx={{
            top:
              studentJoined || slotsContextData.student_join_time !== null
                ? "max(6%, 50px)"
                : "13.6%",
            ...(window.location.pathname === "/FeedbackOverview/offline" && {
              top: "max(6%, 49px)",
              height:
                feedbackFocused && selectedTab === "All"
                  ? "100vh"
                  : "max(25%, 435px)",
              backgroundColor: "rgba(164, 164, 164, 0.9)",
            }),
            ...(window.innerWidth > 1500 && { left: "2.5%" }),
          }}
          onClick={handleBackgroundClick}
        />
      )}
    </Box>
  );
}
