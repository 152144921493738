// import AsyncStorage from "@react-native-async-storage/async-storage";
// import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import BackendDownErrorHandling from "../screens/Reusables/BackendDownErrorHandling/BackendDownErrorHandling";
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Credentials": true,
    "Access-Control-Allow-Origin": "*",
  },
});
let jwt_token = "Bearer " + Cookies.get("token");

class Service {
  constructor(setUnderMaintenanceCalled) {
    this.setUnderMaintenanceCalled = setUnderMaintenanceCalled;
  }
  post = async (endpoint, data) => {
    try {
      jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.post(endpoint, data);
      return res;
    } catch (err) {
      return this.handleError(err);
    }
  };

  get = async endpoint => {
    // console.log("getreq", Cookies.get("token") ? "user" : "ytfkuv");
    try {
      jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.get(endpoint);
      return res.data;
    } catch (err) {
      return this.handleError(err);
    }
  };

  // getFull = async endpoint => {
  //   api.defaults.headers.common["Authorization"] =
  //     "Bearer " + (await AsyncStorage.getItem("token"));
  //   let res = await api.get(endpoint);
  //   return res;
  // };

  // patch = async (endpoint, data) => {
  //   api.defaults.headers.common["Authorization"] =
  //     "Bearer " + (await AsyncStorage.getItem("token"));
  //   let res = await api.patch(endpoint, data);
  //   return res.data;
  // };

  delete = async endpoint => {
    try {
      jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.delete(endpoint);
      return res.data;
    } catch (err) {
      return this.handleError(err);
    }
  };
  put = async (endpoint, data) => {
    try {
      jwt_token = "Bearer " + Cookies.get("token");
      api.defaults.headers.common["Authorization"] = jwt_token; //+ await AsyncStorage.getItem('token');
      let res = await api.put(endpoint, data);
      return res.data;
    } catch (err) {
      return this.handleError(err);
    }
  };

  handleError = err => {
    if (err.response) {
      const { status } = err.response;
      if (status === 501 || status === 502) {
        console.error(`Error ${status}: ${err.message}`);
        this.setUnderMaintenanceCalled(true); // Update context state
        return <BackendDownErrorHandling />;
      }
    } else if (err.message === "Network Error") {
      console.error("Network or other error:", err.message);
      this.setUnderMaintenanceCalled(true); // Update context state
      return <BackendDownErrorHandling />;
    }

    console.error("Unhandled Error:", err);
    throw err;
  };
}

export default Service;
