import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { Button, Menu } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import debounce from "lodash.debounce";
import { Color } from "../../../../GlobalStyles";
import { AppContext } from "../../../AppContext";
import { UnderMaintenanceContext } from "../../../UnderMaintenanceContext";
import BackendDownErrorHandling from "../../Reusables/BackendDownErrorHandling/BackendDownErrorHandling";
import SideNavBar from "../../SideNavBar/SideNavBar";
import { PastBookingsSkeletonLoader } from "../../SkeletonLoader/SkeletonLoader";
import { checkDate } from "../../../services/dateFunctions";
import Service from "../../../services/httpService";
import {
  calculateEpochTimeInLocalTime,
  extractDateFromDateString,
} from "../../../services/specialCharReplace";
import "./MentorPastBookings.css";
const MentorPastBookings = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const formInputs = useContext(AppContext);
  console.log("formInputs", formInputs);
  const [pastBookingsDetails, setPastBookingsDetails] = useState([]);
  const [filteredList, setFilteredList] = useState(pastBookingsDetails);
  const [condition, setCondition] = useState("Past Bookings");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { underMaintenanceCalled, setUnderMaintenanceCalled } = useContext(
    UnderMaintenanceContext
  );
  console.log("filteredList", filteredList);
  const [timerEnded, setTimerEnded] = useState(false);

  const FeedbackRemainingHoursTimer = ({ startTime, sessionId }) => {
    console.log("current start time", startTime);
    const calculateTimeRemaining = () => {
      const currentTime = Date.now();
      console.log("current time", currentTime);
      const timeRemainingMilliseconds = startTime - currentTime;
      console.log("current remaining time", timeRemainingMilliseconds);
      const remainingHours = Math.floor(
        timeRemainingMilliseconds / (60 * 60 * 1000)
      );
      console.log("current remaining hours", remainingHours);
      return remainingHours >= 0 ? remainingHours : 0;
    };
    const [timeRemaining, setTimeRemaining] = useState(
      calculateTimeRemaining()
    );

    useEffect(() => {
      const timer = setInterval(() => {
        const remainingTime = calculateTimeRemaining();
        setTimeRemaining(remainingTime);
        if (remainingTime <= 0) {
          const updatedTimerValue = pastBookingsDetails.map(timer => {
            if (timer.id === sessionId) {
              setTimerEnded(true);
              return {
                ...timer,
                timer_ended: true,
              };
            } else {
              return timer;
            }
          });
          setPastBookingsDetails(updatedTimerValue);
        }
      }, 1000);
      return () => clearInterval(timer);
    }, []);

    const formatTime = time => {
      if (time === 1) {
        return `${time} hour`;
      } else {
        return `${time} hours`;
      }
    };

    return (
      <div>
        {timerEnded ? <p>00:00</p> : <p>{formatTime(timeRemaining)}</p>}
      </div>
    );
  };
  console.log("timerEnded", timerEnded);
  const location = useLocation();
  let user_id = location.state?.user_id;
  console.log("user idddd", user_id);
  const services = new Service(setUnderMaintenanceCalled);

  const tableTitles = [
    "Activity Name",
    "Student Name",
    "Date and Time",
    "Duration",
    "Feedback",
  ];

  const [options, setOptions] = useState([
    { label: "All", value: "All", isSelected: true },
    { label: "Completed", value: "Completed", isSelected: false },
    { label: "Missed", value: "Missed", isSelected: false },
    {
      label: "Student Marked Absent",
      value: "Student Marked Absent",
      isSelected: false,
    },
    { label: "Feedback Pending", value: "Feedback Pending", isSelected: false },
    { label: "Expired", value: "Expired", isSelected: false },
  ]);

  const uniqueActTypeNames = new Set();
  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      let bookingsData;
      try {
        setLoading(true);
        if (formInputs?.user_id !== null) {
          bookingsData = await fetchRecentActivityData(
            formInputs?.formInputs?.user_id
          );
          let temp_past_act = bookingsData?.filter(
            obj =>
              !(
                checkDate(extractDateFromDateString(obj.start_time)) ===
                  "today" ||
                checkDate(extractDateFromDateString(obj.start_time)) ===
                  "tomorrow" ||
                checkDate(extractDateFromDateString(obj.start_time)) ===
                  "yesterday" ||
                checkDate(extractDateFromDateString(obj.start_time)) ===
                  "day before yesterday"
              )
          );

          const temp_data = temp_past_act?.map(booking => {
            return {
              ...booking,
              timer_ended: false,
            };
          });

          const updatedTempData = temp_data.map(booking => {
            if (timerEnded) {
              return {
                ...booking,
                timer_ended: true,
              };
            }
            return booking;
          });
          console.log("temp_past_act", temp_past_act);
          console.log("temp_past_act", updatedTempData);
          if (currentPage === 1) {
            setPastBookingsDetails(updatedTempData);
          } else {
            setPastBookingsDetails(prevDetails => [
              ...prevDetails,
              ...updatedTempData,
            ]);
          }
          setLoading(false);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setLoading(false);
        window.alert(err.response.data.message);
      }
    };
    handleEffect();
  }, [formInputs, currentPage]);

  console.log("pastBookingsDetails", pastBookingsDetails, currentPage);
  console.log("filteredList", filteredList);
  const fetchRecentActivityData = async mentor_id => {
    console.log("the mentor id", mentor_id);
    try {
      setLoading(true);
      const res = await services.get(
        `mentorModule/pastBookingMentor?mentor_id=${mentor_id}&limit=5&page=${currentPage}`
      );
      console.log("data obtained", res.data);
      setLoading(false);
      return res.data;
    } catch (err) {
      setLoading(false);
      console.log(err.message || err.response.data.message);
      window.alert(err.response.data.message);
    }
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;

      if (windowHeight + scrollTop + 20 >= documentHeight) {
        setLoading(true);
        setCurrentPage(prev => {
          if (pastBookingsDetails?.length > 0) {
            return prev + 1;
          } else {
            return prev;
          }
        });
      }
      setLoading(false);
    }, 500);

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pastBookingsDetails, currentPage]);

  console.log("bookings list", pastBookingsDetails);
  console.log("loadingScroll", pastBookingsDetails.length);

  const requestSearch = searched => {
    console.log("searched text", searched);
    setFilteredList(
      pastBookingsDetails.filter(item => item.act_type_name?.includes(searched))
    );
    handleClose();
  };
  console.log("filtered list", filteredList);
  console.log("condition", condition);

  const navigateToFeedbackOverview = slot => {
    window.sessionStorage.setItem("slot", JSON.stringify(slot));
    let temp_condition = "Past Bookings";
    setCondition(temp_condition);
    navigate(`/FeedbackOverview?order_id:${slot.id}`, { state: slot });
  };

  const navigateToFeedbackReview = slot => {
    window.sessionStorage.setItem("slot", JSON.stringify(slot));
    let temp_condition = "Past Bookings";
    setCondition(temp_condition);
    navigate(`/FeedbackReview?order_id=${slot.id}`, { state: slot });
  };
  const currentDate = new Date().getTime();

  // filter search
  const requestSearchFilter = (searched, isSelected) => {
    console.log("searched", searched);
    let filteredList;
    const updatedOptions = options.map(option => ({
      ...option,
      isSelected: option.value === searched ? !option.isSelected : false,
    }));

    console.log("options selected", updatedOptions);
    setOptions(updatedOptions);

    if (searched === "Completed") {
      filteredList = pastBookingsDetails.filter(
        details =>
          details.mentor_feedback_submitted === 1 &&
          details.order_flag === "Done"
      );
    } else if (searched === "Missed") {
      filteredList = pastBookingsDetails.filter(
        details =>
          details.mentor_feedback_submitted === 0 &&
          currentDate >= details.end_time + 900000 &&
          details.order_flag === "Scheduled"
      );
    } else if (searched === "Student Marked Absent") {
      filteredList = pastBookingsDetails.filter(
        details => details.order_flag === "Done" && details.is_absent === 1
      );
    } else if (searched === "Feedback Pending") {
      filteredList = pastBookingsDetails.filter(
        details =>
          details.order_flag === "Feedback" && details.timer_ended !== true
      );
    } else if (searched === "Expired") {
      filteredList = pastBookingsDetails.filter(details => details.timer_ended);
    } else if (searched === "All") {
      filteredList = pastBookingsDetails;
    } else {
      filteredList = pastBookingsDetails.filter(
        item =>
          item.order_flag?.toLowerCase().includes(searched.toLowerCase()) ||
          item.order_flag?.toUpperCase().includes(searched.toUpperCase())
      );
    }

    console.log("filtered list", filteredList);
    if (filteredList.length === 0) {
      console.log("i'm here");
      setFilteredList([]);
    } else {
      console.log("now, i'm here");
      setFilteredList(filteredList);
    }
  };

  return (
    <>
      {underMaintenanceCalled ? (
        <BackendDownErrorHandling />
      ) : (
        <div
          className="OngoingEventsCard"
          style={{ marginTop: "2rem", marginLeft: "10rem" }}
        >
          <SideNavBar />
          <div className="topHeadings" style={{ display: "flex" }}>
            <p style={{ marginBottom: "1rem" }}>Past Bookings</p>
          </div>
          <div>
            <p style={{ color: Color.neutralMidGrey, marginBottom: "1rem" }}>
              If feedback has not been completed within{" "}
              <span style={{ color: "red" }}>48 hours</span> after the
              completion of the activity,
              <span style={{ color: "red" }}>
                {" "}
                booking of any new sessions will be disabled.
              </span>{" "}
              <br /> If feedback has not been completed within{" "}
              <span style={{ color: "red" }}>72 hours</span> after the
              completion of the activity, the respective activity will be deemed{" "}
              <span style={{ color: "red" }}>invalid</span>.
            </p>
          </div>
          <div>
            {options.map((option, index) => (
              <button
                key={index}
                className={
                  option.isSelected === true
                    ? "optionsFocusButton"
                    : "Optionsbutton"
                }
                style={{}}
                onClick={() => {
                  requestSearchFilter(option.value, !option.isSelected);
                }}
              >
                {option.label}
              </button>
            ))}
          </div>
          {loading && currentPage === 1 ? (
            <>
              <div
                style={{
                  marginBottom: "1.5rem",
                }}
              >
                <PastBookingsSkeletonLoader />
              </div>
              <div
                style={{
                  marginBottom: "1.5rem",
                }}
              >
                <PastBookingsSkeletonLoader />
              </div>
              <div
                style={{
                  marginBottom: "1.5rem",
                }}
              >
                <PastBookingsSkeletonLoader />
              </div>
            </>
          ) : (
            <div style={{ position: "relative" }}>
              <TableContainer>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  style={{ width: "90%" }}
                >
                  <TableHead>
                    <TableRow>
                      {tableTitles.map((title, index) => (
                        <TableCell
                          align={index === 0 ? "left" : "center"}
                          key={index}
                          sx={{ fontSize: 16, color: Color.neutralMidGrey }}
                        >
                          {title}
                          {index === 0 ? (
                            <>
                              <Button
                                onClick={handleClick}
                                sx={{ color: Color.neutralMidGrey }}
                              >
                                <UnfoldMoreIcon />
                              </Button>
                              <Menu
                                id="account-menu"
                                anchorEl={anchorEl}
                                open={open}
                                sx={{ borderRadius: "16px" }}
                                onClose={handleClose}
                                PaperProps={{
                                  elevation: 3,
                                  sx: {
                                    borderRadius: "16px",
                                    color: Color.neutralMidGrey,
                                  },
                                }}
                                MenuListProps={{
                                  sx: {
                                    width: "230px",
                                    color: Color.neutralMidGrey,
                                  },
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                {pastBookingsDetails?.map(item => {
                                  // Check if the act_type_name is already in the Set
                                  if (
                                    !uniqueActTypeNames.has(item.act_type_name)
                                  ) {
                                    // If not, add it to the Set and render the Button and <br />
                                    uniqueActTypeNames.add(item.act_type_name);

                                    return (
                                      <div key={item.act_type_name}>
                                        <Button
                                          onClick={() =>
                                            requestSearch(item.act_type_name)
                                          }
                                          style={{
                                            color: Color.neutralMidGrey,
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {item.act_type_name}
                                        </Button>
                                        <br />
                                      </div>
                                    );
                                  }

                                  // If the act_type_name is already in the Set, return null to skip rendering
                                  return null;
                                })}
                              </Menu>
                            </>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {(filteredList.length > 0
                      ? filteredList
                      : pastBookingsDetails
                    ).map((details, index) => {
                      if (
                        filteredList.length === 0 &&
                        options.slice(1).some(option => option.isSelected)
                      ) {
                        return null;
                      } else {
                        return (
                          <>
                            <TableRow
                              style={{
                                cursor: "pointer",
                                background:
                                  (details.mentor_feedback_submitted === 0 &&
                                    currentDate >= details.end_time + 900000 &&
                                    details.order_flag === "Scheduled") ||
                                  (details.order_flag === "Done" &&
                                    details.is_absent === 1) ||
                                  (details.timer_ended &&
                                    details.order_flag === "Feedback")
                                    ? Color.neutralLightGrey
                                    : "",
                              }}
                            >
                              <TableCell
                                align="left"
                                sx={{
                                  fontSize: 16,
                                  color: Color.neutralMidGrey,
                                }}
                              >
                                <p style={{ color: "black" }}>
                                  {details.act_type_name}
                                </p>
                              </TableCell>
                              <TableCell
                                align="center"
                                sx={{
                                  fontSize: 16,
                                  color: Color.neutralMidGrey,
                                }}
                              >
                                <p style={{ color: "black" }}>
                                  {details.student_name}
                                </p>
                                {details.college_name}
                                <br />
                                {details.college_code}
                                <br />
                                {details.user_group_name}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: 16 }}>
                                {details.start_time
                                  ? extractDateFromDateString(
                                      details.start_time
                                    )
                                  : "N/A"}
                                <br />
                                {calculateEpochTimeInLocalTime(
                                  details.start_time
                                )}{" "}
                                -{" "}
                                {calculateEpochTimeInLocalTime(
                                  details.end_time
                                )}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: 16 }}>
                                {Math.floor(
                                  (details.end_time - details.start_time) /
                                    60000
                                ) + " minutes"}
                              </TableCell>
                              <TableCell align="center" sx={{ fontSize: 16 }}>
                                {details.mentor_feedback_submitted === 1 &&
                                details.order_flag === "Done" ? (
                                  <>
                                    {console.log(
                                      "end time",
                                      currentDate >= details.end_time
                                    )}
                                    <button
                                      style={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "0 auto",
                                      }}
                                      onClick={() =>
                                        navigateToFeedbackReview(details)
                                      }
                                      className="StartSEssionbutton-outline"
                                    >
                                      View Feedback
                                    </button>
                                  </>
                                ) : details.mentor_feedback_submitted === 0 &&
                                  currentDate >= details.end_time + 900000 &&
                                  details.order_flag === "Scheduled" ? (
                                  <>
                                    <button
                                      style={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "0 auto",
                                        background:
                                          details.mentor_feedback_submitted ===
                                            0 &&
                                          currentDate >=
                                            details.end_time + 900000 &&
                                          details.order_flag === "Scheduled"
                                            ? Color.neutralLightGrey
                                            : "",
                                      }}
                                      className="StartSEssionbutton-outline"
                                    >
                                      Activity Missed
                                    </button>
                                  </>
                                ) : details.order_flag === "Done" &&
                                  details.is_absent === 1 ? (
                                  <>
                                    <button
                                      style={{
                                        textAlign: "center",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        margin: "0 auto",
                                        background:
                                          details.order_flag === "Done" &&
                                          details.is_absent === 1
                                            ? Color.neutralLightGrey
                                            : "",
                                      }}
                                      className="StartSEssionbutton-outline"
                                    >
                                      Student Marked Absent
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    {details.order_flag === "Feedback" &&
                                    details.timer_ended ? (
                                      <>
                                        <button
                                          style={{
                                            textAlign: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            margin: "0 auto",
                                            background: Color.neutralLightGrey,
                                          }}
                                          className="StartSEssionbutton-outline"
                                        >
                                          Feedback Expired
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          style={{
                                            textAlign: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "auto",
                                            margin: "0 auto",
                                            border: "1px solid red",
                                            borderRadius: "11px",
                                            padding: "10px",
                                          }}
                                          className="StartSessionbutton-outline"
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                            onClick={() =>
                                              navigateToFeedbackOverview(
                                                details
                                              )
                                            }
                                          >
                                            <p
                                              style={{
                                                color: "red",
                                                margin: 0,
                                                cursor: "none",
                                              }}
                                            >
                                              Complete Feedback
                                            </p>
                                            {console.log(
                                              "details.end_time",
                                              details.end_time
                                            )}
                                            <p
                                              style={{
                                                color: Color.neutralMidGrey,
                                                margin: 0,
                                              }}
                                            >
                                              within{" "}
                                              <FeedbackRemainingHoursTimer
                                                startTime={
                                                  details.end_time + 259200000
                                                }
                                                sessionId={details.id}
                                              />
                                            </p>
                                          </div>
                                        </button>
                                      </>
                                    )}
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
          {loading && (
            <div style={{}}>
              <PastBookingsSkeletonLoader />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MentorPastBookings;
