import { Flex } from '@100mslive/react-ui'
import React, { createContext, useEffect, useState, useContext, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { RouteList } from '../../App'
import StudentNotes from '../Students/StudentNotes/StudentNotes'
import Feedback from '../Mentors/Feedback/Feedback'
import ApplyNow from '../ApplyNow/ApplyNow'
import Cookies from 'js-cookie'
// import { useHMSActions} from '@100mslive/react-sdk'
import Service from '../../services/httpService'
import { Box } from '@mui/material'
import { Header } from '../../components/Header'
import { SlotsContext } from '../../AppContext'
import DocumentsAndFeedback from '../DocumentsAndFeedback/DocumentsAndFeedback'
// import { useHMSActions } from "@100mslive/react-sdk";
import TimeService from '../../services/epochTime'
import { selectPeers, useHMSActions, useHMSStore } from "@100mslive/react-sdk";
import Alert from '../../components/Alert/Alert'
import { ActivityLoader } from '../../components/Loader/Loader'
export const Time = createContext()

const VideoConferencing = ({ getUserToken, getDetails, authTokenByRoomCodeEndpoint }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const hmsActions = useHMSActions()
  const services = new Service()
  const slot = JSON.parse(window.sessionStorage.getItem("slot"))
  console.log('slot', slot);
  const { slotsContextData, setSlotsContextData } = useContext(SlotsContext)
  console.log("slots data", slotsContextData);
  const checkUser = Cookies.get('user_type')
  const time = new TimeService();
  const [studentJoined, setStudentJoined] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [userNotJoinedRoomModal, setUserNotJoinedRoomModal] = useState(false);
  // const [overflowHidden, setOverflowHidden] = useState(false)
  const [loading, setLoading] = useState(false);
  const peers = (useHMSStore(selectPeers) || []).filter(
    peer =>
      peer.videoTrack || peer.audioTrack || peer.auxiliaryTracks.length > 0
  );
  const { hmsSDKPeers, isRoomJoinCalled } = hmsActions;
  useEffect(() => {
    const peer = Object.keys(hmsSDKPeers).map(
      peer_element => hmsSDKPeers[peer_element]
    );
    // console.log("overflowHidden in video", overflowHidden);
    const mentor_peer = peer?.find(
      one_peer => one_peer?.role?.name === "mentor"
    ) || { joinedAt: "" };

    if (mentor_peer.joinedAt) {
      const mentorDate = new Date(mentor_peer?.joinedAt);
      const mentorTimeMS = time.convertTimeToMS(
        mentorDate.getHours(),
        mentorDate.getMinutes()
      );
      window.sessionStorage.setItem("timestamp_pageOpened", mentorTimeMS);
    }
    const student_peer = peer?.find(
      one_peer => one_peer?.role?.name === "student"
    );
    console.log("student_peer", student_peer);
    if (peers.some(peer_element => peer_element.roleName === "student")) {
      window.sessionStorage.setItem("student_joined", 1);
      setStudentJoined(true);
    }

    console.log("isRoomJoinCalled", isRoomJoinCalled);
    console.log("peers:", peers);
  }, [peers.length]);

  const fetchGetRequest = useCallback(async () => {
    try {
      setLoading(true)
      console.log("updating the session");
      let res = await services.get(`/superadmin/order?order_id=${slot.id}`)
      console.log("res is not happening");
      slot.mentor_joined = res.data[0]?.mentor_joined
      slot.order_flag = res.data[0]?.order_flag
      const session_guidelines = res.session_guidelines;
      window.sessionStorage.setItem("slot", JSON.stringify(slot))
      setSlotsContextData({
        ...res.data[0],
        session_guidelines:session_guidelines,
        duration: res.sku_info.duration,
        sku_name: res.sku_info.sku_name,
        mentor_first_name: res.mentor_info.first_name,
        mentor_last_name: res.mentor_info.last_name,
        student_first_name: res.student_info.first_name,
        student_last_name: res.student_info.last_name,
      });
      setLoading(false)
    } catch (err) {
      console.error(err.response.data.message)
    }
  }, [])

  useEffect(() => {
    fetchGetRequest()
    return () => {
      hmsActions.leave()
    }
  }, [])


  const endRoom = useCallback(async () => {
    try {
      const lock = false; // set to true to disallow rejoins
      const reason = 'party is over';
      await hmsActions.endRoom(lock, reason);
      // navigate(`/EndSession`, { state: { user_type: checkUser } });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleEndSessionYes = async () => {
    try {
      await hmsActions.endRoom(false,"the party is still on")
      navigate(`/EndSession`, { state: { user_type: checkUser } });

      if (checkUser === "mentor") {
        let body = {
          order_id: slotsContextData.id,
          is_absent: 0,
        };
        await services.put(`/scheduling/session/endSession`, body);
      }

      sessionStorage.clear();
    } catch (err) {
      window.alert(err.response.data.message);
    }
  };



  useEffect(() => {
    let interval = null;
    if (slotsContextData?.mentor_join_time === null || slotsContextData?.student_join_time === null) {
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds(seconds => seconds - 1);
        }, 1000);
      }
      else {
        // Timer has finished
        clearInterval(interval);
        onFinish(); // Call onFinish function
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  console.log("seconds", seconds);

  const onFinish = () => {
    setUserNotJoinedRoomModal(!isRoomJoinCalled);

    if (!isRoomJoinCalled && window.sessionStorage.getItem("userNotJoinedRoomModalShown") !== "true" && window.location.pathname !== "/FeedbackOverview") {
      setUserNotJoinedRoomModal(true);
      window.sessionStorage.setItem("userNotJoinedRoomModalShown", "true");
    }

    window.sessionStorage.setItem("room_join_alert", "true");

  };

  const alertResponse = async data => {
    setUserNotJoinedRoomModal(false)
  };



  return (
    <>
      {!["streaming", "preview", "meeting", "leave"].includes(location.pathname.split("/")[1]) ? (
        <ApplyNow />
      ) : (slotsContextData && slotsContextData.session_guidelines !== undefined ?
        (<>
          <div className="hide-scrollbar" style={{
            background: "#202124", width: "100%", height: '100vh', padding: "0px", margin: "0px",
            // overflowY: checkUser === "mentor" ? (overflowHidden ? "scroll" : "hidden") : "hidden", 
            // overflowY: "hidden",
            // overflowX: 'auto'
          }}>
            <div className="hide-scrollbar" style={{
              width: "1460px", height: "100vh", display: "flex", flexDirection: "column", padding: "0px", margin: "0px",
              // overflowY: checkUser === "mentor" ? (overflowHidden ? "scroll" : "hidden") : "hidden", 
              // overflowY: "hidden",
              // overflowX: 'auto',
              background: "#202124", margin: "0 auto"
            }}>

              <Header isPreview={(!studentJoined) || (slotsContextData.student_join_time === null)} studentJoined={studentJoined} handleEndSessionYes={handleEndSessionYes} />
              <div className="hide-scrollbar" style={{ flex: 1, overflowY: "auto", overflowX: 'hidden', width: "1460px", margin: "0 auto" }}>
                <Flex
                css={{
                  size: "100%",
                  justifyContent: "center",
                  position: "relative",
                  background: "#202124",
                  display: "auto",
                  flexDirection: "auto",
                  gap: "1.5rem",
                }}
              >
                {checkUser === "mentor" && slotsContextData ? (

                  <DocumentsAndFeedback
                    // overflowHidden={overflowHidden} setOverflowHidden={setOverflowHidden} 
                    hmsActions={hmsActions} checkUser={checkUser} studentJoined={studentJoined} handleEndSessionYes={handleEndSessionYes} />
                ) : ["student b2c", "student b2b"].includes(checkUser) && slotsContextData ? (
                  <DocumentsAndFeedback
                    // overflowHidden={overflowHidden} setOverflowHidden={setOverflowHidden} 
                    hmsActions={hmsActions} checkUser={checkUser} studentJoined={studentJoined} handleEndSessionYes={handleEndSessionYes} />
                ) : (
                  navigate('/')
                )}

                <Flex css={{ height: "100%", width: "30%" }} direction={["student b2c", "student b2b"].includes(checkUser) ? "" : "column"}>
                  <RouteList
                    getUserToken={getUserToken}
                    getDetails={getDetails}
                    authTokenByRoomCodeEndpoint={authTokenByRoomCodeEndpoint}
                    checkUser={checkUser}
                  />
                </Flex>
              </Flex>
              </div>
            </div>
          </div>
        </>) : loading ? (<><ActivityLoader show={loading}/></>) : <></>

      )}
      <Alert
        alert={userNotJoinedRoomModal}
        setAlert={setUserNotJoinedRoomModal}
        body="It looks like you are still waiting outside the meeting room. Enter your name and click `Join Now` to start your session."
        noButton="OK"
        yesButton="Got it"
        responseAlert={alertResponse}
        onHide={() => setUserNotJoinedRoomModal(false)}
      />
    </>
  )
}

export default VideoConferencing
