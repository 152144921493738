import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import * as posenet from "@tensorflow-models/posenet";
import * as tf from "@tensorflow/tfjs";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Webcam from "react-webcam";
import Navbar from "../../components/AppBar/Navbar";
import Service from "../../services/httpService";
import SideNavBarStudent from "../SideNavBar/SideNavBarStudent";

function AssessmentTest() {
  const services = new Service();
  const webcamRef = useRef(null);
  const navigate = useNavigate(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const query_test_id = queryParams.get("test_id");
  const query_test_type = queryParams.get("test_type_id");
  const fr = queryParams.get("fr"); //Face recogniazion in query
  const query_role = queryParams.get("role_id"); //Face recogniazion in query

  const [modal, setModal] = useState(false);
  const [testLoader, setTestLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [modalIndex, setModalIndex] = useState(0);
  const [testTypes, setTestTypes] = useState([]);
  const [onGoingTest, setOngoingTest] = useState([]);
  const [completedTest, setCompletedTest] = useState([]);
  const [tests, setTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [selectedSpecialization, setSelectedSpecialization] = useState(null);
  const [allRoles, setAllRoles] = useState([]);
  const [sjtRoles, setsJtRoles] = useState([]);
  const [allSpecializations, setAllSpecializations] = useState([]);
  const [selectedRolePrepardness, setSelectedRolePrepardness] =
    useState("Role");
  const [inputValue, setInputValue] = useState("");

  const [faceError, setFaceError] = useState("");
  const handleTestTypeSelection = index => {
    let temp_test_type = testTypes.map((item, test_index) => {
      if (test_index === index) {
        return {
          ...item,
          selected: true,
        };
      } else {
        return {
          ...item,
          selected: false,
        };
      }
    });
    if (testTypes[index].test_sections !== null) {
      setSelectedRoles(testTypes[index].test_sections[0]);
    }
    setTestTypes(temp_test_type);
    if (index === 4) {
      setSelectedRoles(sjtRoles[0]);
    }
  };

  const handleTestSelection = (item, type) => {
    if (type === "unAttempted") {
      let selected_test = tests.find(
        (test, t_index) => test.test_id === item.test_id
      );

      setSelectedTest(selected_test);
      setModal(true);
    } else {
      let selected_test = onGoingTest.find(
        (test, t_index) => test.test_id === item.test_id
      );
      console.log("SelectedTest", selected_test);
      setSelectedTest(selected_test);
      setModal(true);
    }
  };
  const handleRoleSelect = e => {
    if (selectedRolePrepardness === "Role") {
      setSelectedRoles(e.target.value);
    } else {
      setSelectedSpecialization(e.target.value);
    }
  };

  useEffect(() => {
    const getTestData = async () => {
      setTestLoader(true);
      const token = Cookies.get("token");
      if ([null, undefined, ""].includes(token)) {
        navigate("/");
        return;
      }
      const allTests = await services.get("/tests/getTestType");
      const allRoles = await services.get("/superAdmin/role");
      const temp_sjtRoles = await services.get(
        "/jobs/rolesByTestTypeId?test_type_id=5"
      );

      const allSpecialization = await services.get(
        "/superAdmin/specialization"
      );

      setAllRoles(allRoles.data);
      setsJtRoles(temp_sjtRoles.data);
      setSelectedRoles(allRoles.data[0]);
      setAllSpecializations(allSpecialization.data);
      setSelectedSpecialization(allSpecialization.data[0]);
      console.log("QUERY TEST TYPE", query_test_type);
      let temp_test_types = allTests.data.map((item, index) => {
        if (
          [null, undefined, "", "null", "undefined"].includes(query_test_type)
        ) {
          return {
            ...item,
            selected: index === 1 ? true : false,
          };
        } else {
          if (parseInt(query_test_type) === item.test_type_id) {
            const test_Section =
              item.test_sections !== null
                ? JSON.parse(item.test_sections).sections
                : null;
            let id = test_Section.find(ele =>
              ele.test_ids.split(",").includes(query_test_id)
            );

            console.log("fldkjslfjldsjflsd", test_Section, id);

            setSelectedRoles(id ? id : test_Section[0]);
          }
          return {
            ...item,
            selected:
              parseInt(query_test_type) === item.test_type_id ? true : false,
          };
        }
      });

      [temp_test_types[0], temp_test_types[1]] = [
        temp_test_types[1],
        temp_test_types[0],
      ];

      temp_test_types = temp_test_types.map(item => {
        return {
          ...item,
          test_sections:
            item.test_sections === null
              ? null
              : JSON.parse(item.test_sections).sections,
        };
      });
      setTestTypes(temp_test_types);

      if (![null, undefined, ""].includes(query_role)) {
        setTestLoader(true);
        const questions = await services.get(
          `/jobs/assessmentByRoleId?role_id=${query_role}&test_type_id=${query_test_type}`
        );

        let temp_role = allRoles.data.find(
          item => item.id === parseInt(query_role)
        );
        setSelectedRoles(temp_role);

        if (questions.success) {
          setTests(questions.pending_tests[0].test_not_attempted);
          setCompletedTest(manipulateCompletedTest(questions.testDone));
          setOngoingTest(questions.pending_tests[0].ongoingTests);

          if (![null, undefined, ""].includes(query_test_id)) {
            let selected_test =
              questions.pendingTests[0].test_not_attempted.find(
                (test, t_index) => test.test_id === parseInt(query_test_id)
              );
            setSelectedTest(selected_test);
            setModal(true);
          }
          setTestLoader(false);
        }
      }

      if ([true, "true"].includes(fr) && query_test_type !== 1) {
        setTestLoader(true);

        const questions = await services.get(
          `/tests/getAllTestByTestType?test_type_id=${query_test_type}`
        );

        if (questions.success) {
          let selected_test = questions.pendingTests[0].test_not_attempted.find(
            (test, t_index) => test.test_id === parseInt(query_test_id)
          );
          console.log("Selected test", selected_test, typeof query_test_id);
          setSelectedTest(selected_test);
          setModal(true);
          setTests(questions.pendingTests[0].test_not_attempted);
          setCompletedTest(manipulateCompletedTest(questions.testsDone));
          setOngoingTest(questions.pendingTests[0].ongoingTests);
          setTestLoader(false);
        }
      }
    };

    getTestData();
  }, []);

  const manipulateCompletedTest = data => {
    let test = data.map((item, index) => {
      let testDetails =
        typeof item.test_detail === "string"
          ? JSON.parse(item.test_detail)
          : item.test_detail;
      return {
        test_name: item.test_name,
        test_id: item.test_id,
        count_correct_response:
          typeof testDetails.count_correct_response === "string"
            ? JSON.parse(testDetails.count_correct_response)
            : testDetails.count_correct_response,
        mxm_marks: testDetails.mxm_marks,
        percentile: testDetails.percentile,
        score: testDetails.score.toFixed(2),
      };
    });
    console.log("TEDTDONE", test);
    return test;
  };

  useEffect(() => {
    const getTestData = async () => {
      setTestLoader(true);
      let selected_test_type = testTypes.find(t => t.selected === true);
      if (testTypes.length > 0) {
        if (selected_test_type.test_sections !== null) {
          // const questions = await services.get(
          //   `/jobs/assessmentByRoleId?role_id=${selectedRoles.id}&test_type_id=${selected_test_type.test_type_id}`
          // );

          const questions = await services.get(
            `/tests/getTest/section?test_ids=${selectedRoles.test_ids}`
          );

          console.log("Selected Role", questions);
          setTests(questions.pendingTests[0].test_not_attempted);
          setCompletedTest(manipulateCompletedTest(questions.testsDone));
          setOngoingTest(questions.pendingTests[0].ongoingTests);
          setTestLoader(false);
        } else {
          const questions = await services.get(
            `/tests/getAllTestByTestType?test_type_id=${selected_test_type.test_type_id}`
          );

          setTests(questions.pendingTests[0].test_not_attempted);
          setCompletedTest(manipulateCompletedTest(questions.testsDone));
          setOngoingTest(questions.pendingTests[0].ongoingTests);
          setTestLoader(false);
        }
      }
    };

    getTestData();
  }, [
    testTypes,
    selectedRoles,
    selectedSpecialization,
    selectedRolePrepardness,
  ]);

  useEffect(() => {
    const getCameraAccess = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (stream.active) {
        setModalIndex(1);
      }
    };

    if (modal && modalIndex === 0) {
      getCameraAccess();
    }
  }, [modal, modalIndex]);

  useEffect(() => {
    const runPoseEstimation = async () => {
      if (buttonDisabled) {
        tf.setBackend("webgl");
        const net = await posenet.load({
          architecture: "ResNet50",
          outputStride: 16,
          inputResolution: { width: 100, height: 100 },
          quantBytes: 2,
        });
        const webcam = webcamRef.current.video;
        // const pose = await net.estimateSinglePose(webcam, {
        //   flipHorizontal: true,
        // });

        const multiplePose = await net.estimateMultiplePoses(webcam, {
          flipHorizontal: true,
          outputStride: 16,
          maxDetections: 2,
          scoreThreshold: 0.0,
        });
        console.log("POSE", multiplePose);
        let count = 0;
        multiplePose.map(person => {
          if (
            person.score > 0.35 ||
            (person.keypoints[0].score > 0.5 &&
              person.keypoints[1].score > 0.5 &&
              person.keypoints[2].score > 0.5 &&
              person.score > 0.2)
          ) {
            count = count + 1;
          }
        });

        if (count > 1) {
          setFaceError(
            "Multiple individuals Detected, ensuring compliance with the instruction for a singular person at a time."
          );
        } else if (count === 0) {
          setFaceError(
            "No face detected. To start the test please ensure your webcam is on and facing you directly."
          );
        } else {
          setButtonDisabled(false);
          setFaceError("");
        }

        // eslint-disable-next-line no-undef
        tf.dispose(multiplePose);
        tf.dispose(net);
        // eslint-disable-next-line no-unused-expressions
        tf.memory().numTensors; // Check the number of tensors (optional)
        tf.memory().dispose();

        // Check if a key body part (e.g., nose, eyes) is within a certain position range
        // if (
        //   pose.keypoints[0].score < 0.7 ||
        //   pose.keypoints[1].score < 0.8 ||
        //   pose.keypoints[2].score < 0.8
        // ) {
        //   // User may not be sitting in front of the camera
        //   console.log("User is not sitting in front of the camera.");
        //   window.alert("User is not sitting in front of the camera.");
        // } else {
        //   // User is sitting in front of the camera
        //   console.log("User is sitting in front of the camera.");
        //   setButtonDisabled(false);
        // }
      }
    };
    if (modal) {
      const intervalId = setInterval(runPoseEstimation, 5000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [modalIndex, buttonDisabled, modal]);

  return (
    <Box>
      <SideNavBarStudent />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
          width: "85%",
          margin: "auto",
          paddingBlock: "3rem",
        }}
      >
        <Box>
          <Navbar
            title="Assessment Tests"
            description=" Select the category of tests you would like to take"
          />
        </Box>

        <Box>
          {testTypes.length > 0 ? (
            <Box
              sx={{
                display: "flex",
                boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                gap: "32px",
                padding: "8px",
                borderRadius: "15px",
                alignItems: "center",
              }}
            >
              {testTypes.map((test, test_index) => (
                <Box
                  sx={{
                    width: "100%",
                    padding: "10px",
                    backgroundColor: test.selected
                      ? "rgba(56, 193, 223, 0.40)"
                      : "#fff",
                    borderRadius: "10px",
                    "&:hover": { cursor: "pointer" },
                  }}
                  onClick={e => handleTestTypeSelection(test_index)}
                >
                  <Typography sx={{ fontSize: "14px", textAlign: "center" }}>
                    {test.test_type_name}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                boxShadow: " 0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                gap: "48px",
                padding: "8px",
                borderRadius: "15px",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={200}
                height={50}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={200}
                height={50}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={200}
                height={50}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={200}
                height={50}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
            </Box>
          )}
        </Box>

        {testTypes.length > 0 &&
        testTypes.find(item => item.selected).test_sections !== null ? (
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              {selectedRolePrepardness === "Role" ? (
                <Autocomplete
                  value={selectedRoles}
                  onChange={(event, newValue) => {
                    if (![null, undefined].includes(newValue)) {
                      setSelectedRoles(newValue);
                    }
                  }}
                  autoComplete
                  getOptionLabel={options => options.section_name}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={testTypes.find(item => item.selected).test_sections}
                  sx={{ width: 300 }}
                  autoHighlight
                  clearIcon={null}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={
                        testTypes
                          .find(item => item.selected)
                          .test_category.charAt(0)
                          .toUpperCase() +
                        testTypes
                          .find(item => item.selected)
                          .test_category.slice(1)
                      }
                    />
                  )}
                />
              ) : (
                <Select
                  value={selectedRoles}
                  sx={{ width: "300px" }}
                  onChange={e => handleRoleSelect(e)}
                >
                  {allSpecializations.map(item => (
                    <MenuItem key={item} value={item}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          </Box>
        ) : (
          <></>
        )}

        {!testLoader ? (
          <>
            {tests.length > 0 || onGoingTest.length > 0 ? (
              <Box>
                {completedTest.length > 0 && (
                  <Typography sx={{ fontSize: "26px", marginBlock: "1.5rem" }}>
                    Unattempted Test
                  </Typography>
                )}
                <Grid container rowGap={3} columnSpacing={6}>
                  {onGoingTest.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          padding: "16px",
                          boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
                          borderRadius: "20px",
                          height: "100%",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography sx={{ fontSize: "24px" }}>
                            {item.test_name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", color: "#8B8B8B" }}
                          >
                            Duration: {item.duration} mins
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", color: "#8B8B8B" }}
                          >
                            Number of questions: {item.total_no_questions}
                          </Typography>
                        </Box>
                        <button
                          style={{
                            border: "1px solid #1C8EA8",
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                          onClick={() => handleTestSelection(item, "onGoing")}
                        >
                          Resume Test
                        </button>
                      </Box>
                    </Grid>
                  ))}

                  {tests.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          padding: "16px",
                          boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
                          borderRadius: "20px",
                          height: "100%",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography sx={{ fontSize: "24px" }}>
                            {item.test_name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", color: "#8B8B8B" }}
                          >
                            Duration: {item.duration} mins
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", color: "#8B8B8B" }}
                          >
                            Number of questions: {item.total_no_questions}
                          </Typography>
                        </Box>
                        <button
                          style={{
                            border: "1px solid #1C8EA8",
                            width: "100%",
                            borderRadius: "10px",
                            padding: "10px",
                          }}
                          onClick={() =>
                            handleTestSelection(item, "unAttempted")
                          }
                        >
                          Start Test
                        </button>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <></>
            )}

            {completedTest.length > 0 ? (
              <Box>
                <Typography sx={{ fontSize: "26px", marginBlock: "2rem" }}>
                  Completed Test
                </Typography>
                {console.log("ANimeshhhh", completedTest)}
                <Grid container rowGap={3} columnSpacing={6}>
                  {completedTest.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                          padding: "16px",
                          boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
                          borderRadius: "20px",
                          height: "100%",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <Typography sx={{ fontSize: "24px" }}>
                            {item.test_name}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "16px", color: "#8B8B8B" }}
                          >
                            Number of Questions:
                            {item.count_correct_response.skipped +
                              item.count_correct_response.wrong_ans +
                              item.count_correct_response.correct_ans}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box>
                              <Typography
                                sx={{ fontSize: "16px", color: "#8B8B8B" }}
                              >
                                First Attempt
                              </Typography>
                              <Typography
                                sx={{ fontSize: "16px", color: "#1C8EA8" }}
                              >
                                {item.score}/{item.mxm_marks}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{ fontSize: "16px", color: "#8B8B8B" }}
                              >
                                Percentile:
                              </Typography>
                              <Typography
                                sx={{ fontSize: "16px", color: "#1C8EA8" }}
                              >
                                {item.percentile}%
                              </Typography>
                            </Box>
                          </Box>
                          <button
                            style={{
                              border: "none",
                              color: "#1C8EA8",
                              fontSize: "16px",
                            }}
                            onClick={() => {
                              navigate("/Endtest", {
                                state: {
                                  responseData: {
                                    mxm_marks: item.mxm_marks,
                                    score: item.score,
                                  },
                                  test_id: item.test_id,
                                  testType: testTypes.find(
                                    t => t.selected === true
                                  ),
                                },
                              });
                            }}
                          >
                            Review Performance
                          </button>
                        </Box>
                        {/* <button
                        style={{
                          border: "1px solid #1C8EA8",
                          width: "100%",
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                        onClick={() => handleTestSelection(item)}
                      >
                        Start Test
                      </button> */}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: "100px",
              gap: "50px",
              marginTop: "50px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "24px",
                borderRadius: "20px",
                boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={120}
                height={25}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />

              <Skeleton
                variant="rectangular"
                width={210}
                height={30}
                sx={{ borderRadius: "8px" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "24px",
                borderRadius: "20px",
                boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={120}
                height={25}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />

              <Skeleton
                variant="rectangular"
                width={210}
                height={30}
                sx={{ borderRadius: "8px" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "24px",
                borderRadius: "20px",
                boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={120}
                height={25}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />

              <Skeleton
                variant="rectangular"
                width={210}
                height={30}
                sx={{ borderRadius: "8px" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                padding: "24px",
                borderRadius: "20px",
                boxShadow: " 0px 10px 23.8px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={120}
                height={25}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />
              <Skeleton
                variant="rectangular"
                width={120}
                height={20}
                animation="wave"
                sx={{ borderRadius: "8px" }}
              />

              <Skeleton
                variant="rectangular"
                width={210}
                height={30}
                sx={{ borderRadius: "8px" }}
              />
            </Box>
          </Box>
        )}

        <Modal
          open={modal}
          onClose={() => {
            setModal(false);
            setButtonDisabled(true);
          }}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              background: "#fff",
              padding: "20px",
              borderRadius: "30px",
              gap: "16px",
            }}
          >
            {modalIndex === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src="https://du03hetco8xdw.cloudfront.net/Assessment_test/allowAccess.svg"
                  style={{}}
                />
                <Typography
                  sx={{
                    fontSize: "32px",
                    color: "#1C8EA8",
                    textAlign: "center",
                  }}
                >
                  Allow Access
                </Typography>
                <Typography sx={{ fontSize: "16px", textAlign: "center" }}>
                  It is necessary to allow access to the your camera and
                  microphone to start the test.
                  <br /> Click on allow to provide access
                </Typography>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Webcam
                  ref={webcamRef}
                  style={{ width: "800px", height: "400px", padding: "20px" }}
                />
                <Typography
                  sx={{
                    fontSize: "32px",
                    color: "#1C8EA8",
                    textAlign: "center",
                  }}
                >
                  Face Recognition
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    textAlign: "center",
                    marginBlock: "8px",
                  }}
                >
                  Kindly center your face in the frame to be accepted
                </Typography>
                <Typography
                  style={{
                    color: "red",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  {faceError}
                </Typography>

                <button
                  disabled={buttonDisabled}
                  style={{
                    background: buttonDisabled
                      ? "#8B8B8B"
                      : "linear-gradient(133deg, #2AA8C4 16.39%, #38C1DF 100%)",
                    padding: "12px 50px",
                    fontSize: "16px",
                    borderRadius: "10px",
                    color: "#fff",
                  }}
                  onClick={() =>
                    navigate("/Instructions", {
                      state: {
                        selectedTest: selectedTest,
                        testType: testTypes.find(t => t.selected === true),
                      },
                    })
                  }
                >
                  View Instructions
                </button>
              </Box>
            )}
          </Box>
        </Modal>
      </div>
    </Box>
  );
}

export default AssessmentTest;
