export const myProfileData = [
  {
    section_name: "About",
    section_id: 1,
    is_selected: true, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: null, //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: true,
            fields: [
              {
                field_id: 1,
                field_name: "Full Name",
                backend_field_name: "full_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                disabled: true,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 2,
                field_name: "Gender",
                backend_field_name: "gender",
                value: "",
                data_type: "single_dropdown", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                options: ["Male", "Female", "Others"],
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 3,
                field_name: "Date of Birth",
                backend_field_name: "dob",
                value: null,
                data_type: "date", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 4,
                field_name: "Country",
                backend_field_name: "country",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 5,
                field_name: "Home State",
                backend_field_name: "home_state",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 6,
                field_name: "Home Town",
                backend_field_name: "home_town",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 7,
                field_name: "Current State",
                backend_field_name: "current_residential_state",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 8,
                field_name: "Current City",
                backend_field_name: "current_residential_city",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 9,
                field_name: "CAT Percentile",
                backend_field_name: "cat_percentile",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
              {
                field_id: 10,
                field_name: "Languages Known",
                backend_field_name: "languages_known",
                value: [],
                data_type: "multiSelect_ndd", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: false, //true or false or null,
                is_mandatory: true, //true or false
              },
              {
                field_id: 11,
                field_name: "Attach Documents",
                backend_field_name: "supported_docs",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
            ],
            is_edit: false, //true / false / null
            is_verified: null, //true or false or null
            is_delete: null, //true or null
          },
        ],
        ss_name: "About You",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=1&sec_type_id=1`,
        data: null,
        is_edit: true, //true or false or null
        is_add_new: null, //true or null
        is_verified: null, //true or false or null
        group_id: 1, //id
        sec_type_id: 1, // id
      },
    ],
  },
  {
    section_name: "Education",
    section_id: 2,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: null, //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: true,
            fields: [
              {
                field_id: 1,
                field_name: "Percentage",
                backend_field_name: "percentage",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "School Name",
                backend_field_name: "institute_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Board Name",
                backend_field_name: "university_board_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 4,
                field_name: "Year of Passing",
                backend_field_name: "end_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: false, //true / false / null
            is_verified: false, //true or false or null
            is_delete: null, //true or null
          },
        ],
        ss_name: "10th Standard",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=2&sec_type_id=2`,
        data: null,
        is_edit: true, //true or false or null
        is_add_new: null, //true or null
        is_verified: false, //true or false or null
        group_id: 2, //id
        sec_type_id: 2, // id
      },
      {
        items: [
          {
            item_name: null, //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Percentage",
                backend_field_name: "percentage",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "School Name",
                backend_field_name: "institute_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Board Name",
                backend_field_name: "university_board_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 4,
                field_name: "Year of Passing",
                backend_field_name: "end_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: false, //true / false / null
            is_verified: null, //true or false or null
            is_delete: null, //true or null
          },
        ],
        ss_name: "12th Standard",
        ss_id: 2,
        get_url: `/po/student/sTypeWiseGet?group_id=2&sec_type_id=3`,
        data: null,
        is_edit: true, //true or false or null
        is_add_new: null, //true or null
        is_verified: false, //true or false or null
        group_id: 2, //id
        sec_type_id: 3, // id
      },

      {
        items: [
          {
            item_name: "Diploma-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Percentage",
                backend_field_name: "percentage",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "48%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "University Name",
                backend_field_name: "institute_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "48%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Course Name",
                backend_field_name: "pg_specialization",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },

              {
                field_id: 4,
                field_name: "Start Date",
                backend_field_name: "start_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "48%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "End Date",
                backend_field_name: "end_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "48%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 6,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: [],
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Diploma",
        ss_id: 3,
        get_url: `/po/student/sTypeWiseGet?group_id=2&sec_type_id=16`,
        data: null,
        is_edit: true, //true or false or null
        is_add_new: true, //true or null
        is_verified: false, //true or false or null
        group_id: 2, //id
        sec_type_id: 16, // id
      },

      {
        items: [
          {
            item_name: "Undergraduation-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: true,
            fields: [
              {
                field_id: 1,
                field_name: "Degree",
                backend_field_name: "degree",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Institute Name",
                backend_field_name: "institute_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Specialization",
                backend_field_name: "pg_specialization",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 4,
                field_name: "University Name",
                backend_field_name: "university_board_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "Overall Grade/Score",
                backend_field_name: "grade_score",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 6,
                field_name: "Percentage Equivalent",
                backend_field_name: "percentage",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 7,
                field_name: "Year of Joining",
                backend_field_name: "start_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 8,
                field_name: "Year of Passing",
                backend_field_name: "end_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 9,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: false, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Undergraduation",
        ss_id: 4,
        get_url: `/po/student/sTypeWiseGet?group_id=2&sec_type_id=4`,
        data: null,
        is_edit: null, //true or false or null
        is_add_new: true, //true or false or null
        is_verified: false, //true or false or null
        group_id: 2, //id
        sec_type_id: 4, // id
      },
      {
        items: [
          {
            item_name: null, //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: true,
            fields: [
              {
                field_id: 1,
                field_name: "Majors",
                backend_field_name: "specialization_major",
                specialization_major_id: null,
                value: "",
                options: [],
                data_type: "single_dropdown", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "47.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Minors",
                backend_field_name: "specialization_minor",
                specialization_minor_id: null,
                value: "",
                options: [],
                data_type: "single_dropdown", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "47.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Final Results Declared Yet",
                backend_field_name: "final_result_declared",
                value: "",
                options: ["Yes", "No"],
                data_type: "single_dropdown", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 4,
                field_name: "Overall Grade/Score",
                backend_field_name: "grade_score",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "Percentage Equivalent",
                backend_field_name: "percentage",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "30%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 6,
                field_name: "Backlog Subjects",
                backend_field_name: "backlogs",
                value: [],
                data_type: "multiSelect_ndd", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 7,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: false, //true / false / null
            is_verified: false, //true or false or null
            is_delete: null, //true or null
          },
        ],
        ss_name: "MBA/PGDM",
        ss_id: 5,
        get_url: `/po/student/sTypeWiseGet?group_id=2&sec_type_id=6`,
        data: null,
        is_edit: true, //true or false or null
        is_add_new: null, //true or null
        is_verified: false, //true or false or null
        group_id: 2, //id
        sec_type_id: 6, // id
      },

      {
        items: [
          {
            item_name: "Other Postgraduation-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Degree",
                backend_field_name: "degree",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Institute Name",
                backend_field_name: "institute_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Specialization",
                backend_field_name: "pg_specialization",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 4,
                field_name: "University Name",
                backend_field_name: "university_board_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "Overall Grade/Score",
                backend_field_name: "grade_score",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "22.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 6,
                field_name: "Percentage Equivalent",
                backend_field_name: "percentage",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "22.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 7,
                field_name: "Year of Joining",
                backend_field_name: "start_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "22.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 8,
                field_name: "Year of Passing",
                backend_field_name: "end_date",
                value: null,
                data_type: "year", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "22.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 9,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Other Postgraduation",
        ss_id: 6,
        get_url: `/po/student/sTypeWiseGet?group_id=2&sec_type_id=5`,
        data: null,
        is_edit: false, //true or false or null
        is_add_new: true, //true or null
        is_verified: false, //true or false or null
        group_id: 2, //id
        sec_type_id: 5, // id
      },
    ],
  },
  {
    section_name: "Experience",
    section_id: 3,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: null, //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: true,
            fields: [
              {
                field_id: 1,
                field_name: "Total Work Experience",
                backend_field_name: "total_work_ex_in_months",
                value: "",
                data_type: "integer", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
              {
                field_id: 2,
                field_name: "Last Drawn Salary",
                backend_field_name: "last_drawn_annual_salary_ctc",
                value: "",
                data_type: "number", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
              {
                field_id: 3,
                field_name: "Currently on Sabbatical?",
                backend_field_name: "on_sabbatical",
                options: ["Yes", "No"],
                data_type: "single_dropdown", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
              {
                field_id: 4,
                field_name: "Startup Experience (if any)",
                backend_field_name: "startup_exp_in_months",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
              {
                field_id: 5,
                field_name: "Experience Gap",
                backend_field_name: "experience_gap_in_months",
                value: "",
                data_type: "integer", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
              {
                field_id: 6,
                field_name: "Attach Documents",
                backend_field_name: "work_ex_docs",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
                is_mandatory: false, //true or false
              },
            ],
            is_edit: false, //true / false / null
            is_verified: null, //true or false or null
            is_delete: null, //true or null
          },
        ],
        ss_name: "Experience Info",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=1&sec_type_id=1`,
        data: null,
        is_edit: true, //true or false or null
        is_add_new: null, //true or null
        is_verified: null, //true or false or null
        group_id: 1, //id
        sec_type_id: 1, // id
      },
      {
        items: [
          {
            item_name: "Workex-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Organization Name",
                backend_field_name: "organization_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Industry/Sector",
                backend_field_name: "sector_id",
                sector_id: null,
                value: "",
                options: [{ id: null, sector_name: "" }],
                data_type: "single_dropdown", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Start Date",
                backend_field_name: "start_date",
                value: null,
                data_type: "date", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 4,
                field_name: "End Date",
                backend_field_name: "end_date",
                value: null,
                data_type: "date", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "Duration",
                backend_field_name: "duration_in_months",
                value: "",
                data_type: "integer", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 6,
                field_name: "Designation",
                backend_field_name: "designation",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 7,
                field_name: "Role Description",
                backend_field_name: "role_description",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 8,
                field_name: "Location",
                backend_field_name: "location",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 9,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Work Experience Details",
        ss_id: 2,
        get_url: `/po/student/sTypeWiseGet?group_id=3&sec_type_id=8`,
        data: null,
        is_edit: null, //true or false or null
        is_add_new: true, //true or null
        is_verified: false, //true or false or null
        group_id: 3, //id
        sec_type_id: 8, // id
      },
    ],
  },
  {
    section_name: "Internships",
    section_id: 4,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: null, //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: true,
            fields: [
              {
                field_id: 1,
                field_name: "Organization Name",
                backend_field_name: "organization_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Industry/Sector",
                backend_field_name: "sector_id",
                sector_id: null,
                value: "",
                options: [{ id: null, sector_name: "" }],
                data_type: "single_dropdown", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 3,
                field_name: "Start Date",
                backend_field_name: "start_date",
                value: null,
                data_type: "date", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 4,
                field_name: "End Date",
                backend_field_name: "end_date",
                value: null,
                data_type: "date", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 5,
                field_name: "Duration",
                backend_field_name: "duration_in_months",
                value: "",
                data_type: "integer", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 6,
                field_name: "Designation",
                backend_field_name: "designation",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 7,
                field_name: "Role Description",
                backend_field_name: "role_description",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 8,
                field_name: "Location",
                backend_field_name: "location",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 9,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: false, //true / false / null
            is_verified: null, //true or false or null
            is_delete: null, //true or null
          },
        ],
        ss_name: "Summer Internship",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=3&sec_type_id=7`,
        data: null,
        is_edit: true, //true or false or null
        add_new_button: false, //true or false
        is_add_new: null, //true or null
        is_verified: false, //true or false or null
        group_id: 3, //id
        sec_type_id: 7, // id
      },
      {
        items: [
          {
            item_name: "UG Internships-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Organization Name",
                backend_field_name: "organization_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 7,
                field_name: "Role Description",
                backend_field_name: "role_description",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 8,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "UG Internships",
        ss_id: 2,
        get_url: `/po/student/sTypeWiseGet?group_id=3&sec_type_id=9`,
        data: null,
        is_edit: null, //true or false or null
        add_new_button: true, //true or false
        is_add_new: true, //true or null
        is_verified: false, //true or false or null
        group_id: 3, //id
        sec_type_id: 9, // id
      },
      {
        items: [
          {
            item_name: "PG Internships-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Organization Name",
                backend_field_name: "organization_name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "32.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 7,
                field_name: "Role Description",
                backend_field_name: "role_description",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "62.5%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 8,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "PG Internships",
        ss_id: 2,
        get_url: `/po/student/sTypeWiseGet?group_id=3&sec_type_id=10`,
        data: null,
        is_edit: null, //true or false or null
        add_new_button: true, //true or false
        is_add_new: true, //true or null
        is_verified: false, //true or false or null
        group_id: 3, //id
        sec_type_id: 10, // id
      },
    ],
  },
  {
    section_name: "Projects",
    section_id: 5,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: "Project-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Title",
                backend_field_name: "name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Projects",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=4&sec_type_id=11`,
        data: null,
        is_edit: null, //true or false or null
        is_add_new: true, //true or false or null
        is_verified: false, //true or false or null
        group_id: 4, //id
        sec_type_id: 11, // id
      },
    ],
  },
  {
    section_name: "Certifications",
    section_id: 6,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: "Certification-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Title",
                backend_field_name: "name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Certifications",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=5&sec_type_id=13`,
        data: null,
        is_edit: null, //true or false or null
        is_add_new: true, //true or false or null
        is_verified: false, //true or false or null
        group_id: 5, //id
        sec_type_id: 13, // id
      },
    ],
  },
  {
    section_name: "Achievements",
    section_id: 7,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: "Achievement-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Title",
                backend_field_name: "name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Achievements",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=5&sec_type_id=14`,
        data: null,
        is_edit: null, //true or false or null
        is_add_new: true, //true or false or null
        is_verified: false, //true or false or null
        group_id: 5, //id
        sec_type_id: 14, // id
      },
    ],
  },
  {
    section_name: "Extracurricular",
    section_id: 6,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: "Extracurricular-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Title",
                backend_field_name: "name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: null, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Extracurriculars",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=5&sec_type_id=15`,
        data: null,
        is_edit: null, //true or false or null
        is_add_new: true, //true or false or null
        is_verified: false, //true or false or null
        group_id: 5, //id
        sec_type_id: 15, // id
      },
    ],
  },
  {
    section_name: "Leadership Roles",
    section_id: 6,
    is_selected: false, //true or false,
    subSections: [
      {
        items: [
          {
            item_name: "Leadership Role-1", //"" or null,if null then only have one items,
            item_id: null,
            is_mandatory: false,
            fields: [
              {
                field_id: 1,
                field_name: "Title",
                backend_field_name: "name",
                value: "",
                data_type: "string", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "100%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
              {
                field_id: 2,
                field_name: "Attach Documents",
                backend_field_name: "document_url",
                value: "",
                data_type: "document_url", //string / number / single_dropdown / multiSelect_dd / multiSelect_ndd / date / year....,
                width: "90%",
                validation: "",
                is_required: true,
                length: 0, //0 or null
                is_verified: null, //true or false or null,
              },
            ],
            is_edit: true, //true / false / null
            is_verified: false, //true or false or null
            is_delete: true, //true or null
          },
        ],
        ss_name: "Leadership Roles",
        ss_id: 1,
        get_url: `/po/student/sTypeWiseGet?group_id=4&sec_type_id=12`,
        data: null,
        is_edit: null, //true or false or null
        is_add_new: true, //true or false or null
        is_verified: false, //true or false or null
        group_id: 4, //id
        sec_type_id: 12, // id
      },
    ],
  },
];
