import React from "react";
import { useNavigate } from "react-router-dom";
import { CoPresent, Group } from "@mui/icons-material";
import LaptopIcon from "@mui/icons-material/Laptop";
import { Color } from "../../../../GlobalStyles";
function SessionsHome() {
  const categories = ["Online", "Offline", "Group"];

  const navigate = useNavigate();
  return (
    <div>
      <div
        style={{
          fontWeight: 400,
          fontSize: "32px",
          lineHeight: "38px",
          marginLeft: "6px",
          marginBottom: "20px",
          //   textAlign: "left",
        }}
      >
        {" "}
        Your Activities{" "}
      </div>
      <div
        style={{
          padding: "12px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {categories?.map((item, index) => {
          return (
            <div
              onClick={
                index === 0
                  ? () => navigate("/UpcomingBooking")
                  : index === 1
                  ? () => navigate("/OfflineSession")
                  : () => {
                      navigate("/OnGoingGroupSessions");
                    }
              }
              style={{
                width: "27%",
                height: "160px",
                borderRadius: "20px",
                padding: "12px",
                background:
                  index === 0
                    ? "linear-gradient(132.73deg, #2AA8C4 16.39%, #38C1DF 100%)"
                    : index === 1
                    ? "linear-gradient(102.03deg, #F5A536 0%, #FFC87A 100%)"
                    : "linear-gradient(132.73deg, #561D5E 16.39%, #8A4893 100%)",
                "@media (max-width: 600px)": {
                  width: "100%",
                },
                "@media (min-width: 600px) and (max-width: 900px)": {
                  width: "30%",
                },
                "@media (min-width: 900px)": {
                  width: "27%",
                },
              }}
            >
              {" "}
              <div
                style={{
                  width: "100%",
                  padding: "12px 0px 0px 5px",
                  // padding: "12px",
                  fontSize: "32px",
                  lineHeight: "40px",
                  letterSpacing: " 0.24em",
                  textAlign: "left",
                  color: Color.white,
                  fontWeight: "lighter",
                  fontWeight: 100,
                  fontFamily: "Roboto",
                }}
              >
                {" "}
                {item}{" "}
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {index === 0 ? (
                  <LaptopIcon
                    style={{
                      height: "50px",
                      width: "50px",
                      color: "#F2F2F2BF",
                      background:
                        "linear-gradient(359deg, rgba(242, 242, 242, 0.75) -5.76%, rgba(255, 255, 255, 0.75) 114.16%);",
                    }}
                  />
                ) : index === 1 ? (
                  <CoPresent
                    style={{
                      height: "50px",
                      width: "50px",
                      color: "#F2F2F2BF",
                      background:
                        "linear-gradient(359deg, rgba(242, 242, 242, 0.75) -5.76%, rgba(255, 255, 255, 0.75) 114.16%);",
                    }}
                  />
                ) : (
                  <Group
                    style={{
                      height: "50px",
                      width: "50px",
                      color: "#F2F2F2BF",
                      background:
                        "linear-gradient(359deg, rgba(242, 242, 242, 0.75) -5.76%, rgba(255, 255, 255, 0.75) 114.16%);",
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SessionsHome;
